import { Icon } from '@/components/Icon';
import { componentClassName } from '@/styles/SoMe';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { mergeProps } from '@/utils/mergeProps';
import { SocialMediaType } from '.';

export interface SoMeProps extends ComponentProps<'button'> {
  name?: SocialMediaType;
  colors?: ClassNameProp<'plain' | 'filled' | 'border'>;
}

const SoMeComponent: Component<SoMeProps> = ({ children, name, theme, colors, size, variant, ...props }) => {
  const classNameProps = { colors, size, variant };
  const classNameDefaults = { colors: 'filled' };

  const className = cn(componentClassName('', classNameProps, { defaults: classNameDefaults, theme }));

  return (
    <button {...mergeProps({ type: 'button', className }, props)}>
      <Icon name={name} />
      {children}
    </button>
  );
};

export const SoMe = SoMeComponent;
