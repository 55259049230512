import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { ReactNode } from 'react';
import { MorePagesTitle } from './MorePagesTitle';

export interface StandaloneMorePagesTitleProps extends StandaloneComponentProps<typeof MorePagesTitle> {
  content?: ReactNode;
}

export const StandaloneMorePagesTitle: StandaloneComponent<StandaloneMorePagesTitleProps> = ({ content, ...props }) => {
  const [{ type }] = useAppState();
  const title = getOcelotConfig('features.infinityScrollFront.title');

  if (!content && !title) {
    return null;
  }

  return (
    <MorePagesTitle data-page-type={type} {...props}>
      {content || title}
    </MorePagesTitle>
  );
};
