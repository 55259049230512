import { setFirstLoginTime } from 'lib/alltIdMyData';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import useUserAuth from './useUserAuth';

export const useSetFirstLoginTime = () => {
  const { isUserLoggedIn } = useUserAuth();

  const searchParams = useSearchParams();
  const codeAvailable = searchParams.get('code');

  useEffect(() => {
    const loginEnabled = getOcelotConfig('features.login.enabled');

    if (loginEnabled && codeAvailable && isUserLoggedIn) {
      setFirstLoginTime();
    }
  }, [isUserLoggedIn, codeAvailable]);
};
