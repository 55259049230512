import { SliderSlide, SliderSlideProps } from '@/components/Slider/Slide';
import { SliderSwiper, SliderSwiperProps } from '@/components/Slider/Swiper';
import { ClassNameProvider } from '@/styles/ShowsSlider';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { ShowsSlider } from './ShowsSlider';

export interface Show {
  description?: string;
  src?: string;
  playlistCategory?: string;
  playlistId?: string;
  title?: string;
  url?: string;
}

type Extras = {
  $swiperSlide?: SliderSlideProps['options'];
  $swiper?: SliderSwiperProps['options'];
};

export interface StandaloneShowsSliderProps extends StandaloneComponentProps<typeof ShowsSlider, Extras> {
  shows?: Show[];
  caption?: React.ReactNode;
  headline?: React.ReactNode;
}

export const StandaloneShowsSlider: StandaloneComponent<StandaloneShowsSliderProps> = ({
  headline,
  shows,
  caption,
  options,
  ...props
}) => {
  const { $swiper, $slide, $link, $swiperSlide, $header, $title, ...$base } = options ?? {};

  if (!shows?.length) return null;

  const slides = shows.map((show: Show) => (
    <SliderSlide
      url={`${show.url}?pos=showslider`}
      key={`${show.playlistId}-${show.title}`}
      title={<ShowsSlider.Slide {...$slide}>{show.title}</ShowsSlider.Slide>}
      imageSrc={show.src}
      options={{
        $link: {
          className: '!ml-0',
        },
        $image: {
          className: 'aspect-vertical',
        },
        ...$swiperSlide,
      }}
    />
  ));

  return (
    <ClassNameProvider value={$base}>
      <ShowsSlider {...$base} {...props}>
        <ShowsSlider.Header {...$header}>
          <ShowsSlider.Title {...$title}>{headline || 'Våra program'}</ShowsSlider.Title>
          <ShowsSlider.Link
            href="/video/show"
            content={<ShowsSlider.Caption>{caption || 'Se alla'}</ShowsSlider.Caption>}
            {...$link}
          />
        </ShowsSlider.Header>
        <SliderSwiper
          content={slides}
          hideNavigation
          options={mergeOptions(
            {
              speed: 300,
              autoplay: false,
              loop: false,

              breakpoints: {
                0: { slidesPerView: 2.5 },
                360: { slidesPerView: 2.5 },
                480: { slidesPerView: shows.length },
              },
              $arrow: {
                className: 'md:hidden',
              },
              $content: {
                size: 'none',
              },
            },
            $swiper,
          )}
        />
      </ShowsSlider>
    </ClassNameProvider>
  );
};
