import { adsWinAma } from '@/utils/initAdunitElements';
import { isServer } from '@/utils/isServer';

export const setAdPlacementLoaded = (elID: string, placement: string) => {
  if (isServer()) return;

  const uniqueId = elID?.split('-').pop();
  if (window && uniqueId) {
    if (adsWinAma()) {
      window.ama = window.ama || {};
      window.ama.domLoaded = window.ama.domLoaded || {};
      window.ama.domLoaded[uniqueId] = window.ama.domLoaded[uniqueId] || {};
      window.ama.domLoaded[uniqueId][placement] = 1;
    } else {
      window.loadedAdPlacement = window.loadedAdPlacement || {};
      window.loadedAdPlacement[uniqueId] = window.loadedAdPlacement[uniqueId] || {};
      window.loadedAdPlacement[uniqueId][placement] = 1;
    }
  }
};
