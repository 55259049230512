import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = ButtonTheme;

const Row = tw.theme({
  slots: {
    base: `group/row relative mx-auto grid w-full max-w-screen-xl auto-rows-min grid-cols-12 gap-y-8 empty:hidden md:gap-y-2 [&:not(:has(:not(.box:empty)))]:hidden [&>*:not(.grid-cols-12)]:col-span-full`,
    headline: `col-span-12 w-full text-black text-headline-sm after:h-0.25 after:bg-black sm:text-headline-md`,
    caption: `col-span-12 w-full text-black text-body-sm`,
    footer: `col-span-12 flex w-full items-center justify-center [&:not(:first-child)]:mt-12`,
  },
  variants: {
    size: {
      primary: {
        base: `container-col-sm-12:gap-x-2`,
      },
      secondary: {
        base: `gap-x-2 py-4`,
      },
    },
  },
  defaultVariants: {
    size: 'primary',
  },
});

export default Object.assign(Row, { Button });
