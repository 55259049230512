import { saveSeenArticle } from '@/utils/seenArticles';
import { RefObject, useEffect } from 'react';

export const useRegisterArticleSessionView = (ref: RefObject<HTMLElement>, articleId: number) => {
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        saveSeenArticle(articleId);
        observer.disconnect();
      }
    });

    observer.observe(ref.current as Element);

    return () => observer.disconnect();
  }, [ref, articleId]);
};
