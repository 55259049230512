import dayjs from 'dayjs';

/**
 * Formats a given date (either Unix timestamp or string) into a standard 'YYYY-MM-DD HH:mm:ss' format.
 * If the date is invalid, an empty string is returned.
 *
 * @param {string | number} date - The date to be formatted, either as a Unix timestamp or a date string.
 * @returns {string} The formatted date string or an empty string if the date is invalid.
 */
export const getFormattedDate = (date: string | number) => {
  const isUnix = typeof date === 'number' || (/^\d+$/.test(date) && !isNaN(Number(date)));
  const parsedDate = isUnix ? dayjs.unix(Number(date)) : dayjs(date);

  return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD HH:mm:ss') : '';
};
