import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';

let useAma: boolean | undefined = undefined;

export const adsWinAma = () => {
  if (typeof useAma !== 'undefined') return useAma;

  if (isServer()) return;

  useAma = !!['motherhood'].some((site) => {
    const currentSite = window?.location.hostname?.includes('localhost')
      ? process?.env?.OCELOT_SITE
      : window.location?.hostname;

    return currentSite?.includes(site);
  });

  return useAma;
};

export const initAdunitElements = (adsUniqueId?: string, isNativeAd?: boolean, isSecondArticle?: boolean) => {
  if (isServer()) return;

  if (isNativeAd) {
    return;
  }

  if (adsWinAma()) {
    if (typeof window.ama?.init !== 'function') return;

    if (!adsUniqueId) {
      return logger.warn(`Ads unique ID is missing`);
    }

    window.ama.adsUniqueId = `${adsUniqueId}`;
    window.ama.domLoaded = window.ama.domLoaded || {};

    window.ama.init(adsUniqueId, { isSecondArticle });
  } else {
    // This part will be removed when all sites uses window.ama
    if (typeof window.DFPInit !== 'function') return;

    if (!adsUniqueId) {
      return logger.warn(`Ads unique ID is missing`);
    }

    window.adsUniqueId = `${adsUniqueId}`;
    window.DFPInit(adsUniqueId, { isSecondArticle });
  }
};
