import { Button as StandaloneButton } from '@/components/Button';
import { ConfirmationTheme } from '@/components/Confirmation/theme';
import { Timer as StandaloneTimer } from '@/components/Timer';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ConfirmationTheme });

const Base = $({ as: 'section', slot: 'base' });
const Title = $({ as: 'h2', slot: 'title' });
const Banner = $({ as: 'div', slot: 'banner' });
const BannerHeader = $({ as: 'h3', slot: 'bannerHeader' });
const Content = $({ as: 'section', slot: 'content' });
const Timer = GenericSlot({ as: StandaloneTimer, theme: ConfirmationTheme.Timer });
const Button = GenericSlot({ as: StandaloneButton, theme: ConfirmationTheme.Button });

export const Confirmation = Object.assign(Base, {
  Banner: Object.assign(Banner, {
    Header: BannerHeader,
  }),
  Timer,
  Button,
  Title,
  Content,
});
