import { ArticleTeaserDefault as StandaloneArticleTeaserDefault } from '@/components/ArticleTeaser/Default';
import { RecommendedArticlesTheme } from '@/components/RecommendedArticles/theme';
import { Skeleton as StandaloneSkeleton } from '@/components/Skeleton';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: RecommendedArticlesTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'span', slot: 'headline' });
const List = $({ as: 'ul', slot: 'list' });
const ListItem = $({ as: 'li', slot: 'listItem' });
const Skeleton = GenericSlot({ as: StandaloneSkeleton, theme: RecommendedArticlesTheme.Skeleton });
const ArticleTeaser = GenericSlot({
  as: StandaloneArticleTeaserDefault,
  theme: RecommendedArticlesTheme.ArticleTeaser,
});

export const RecommendedArticles = Object.assign(Base, {
  Headline,
  List,
  ListItem,
  ArticleTeaser,
  Skeleton,
});
