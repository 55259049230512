import { LinkTheme } from '@/components/Link/theme';
import { Component, ComponentProps } from '@/types/component';
import { GenericSlot, GenericSlotRender } from 'base/components/GenericSlot';
import NextLink from 'next/link';
import { isNotUndefined } from 'typesafe-utils';

export interface LinkProps extends ComponentProps<'a'> {
  type?: 'reload' | 'next-link' | 'force-reload' | 'force-next-link';
}

const Base: Component<LinkProps> = ({ children, ...props }) => <a {...props}>{children}</a>;

const render: GenericSlotRender<typeof Base> = ({ props, children }) => {
  const { type, href } = props;

  const resolvedType = type || process.env.OCELOT_LINK_TYPE || 'reload';
  const isNextLink = ['next-link', 'force-next-link'].includes(resolvedType);

  if (isNextLink && isNotUndefined(href))
    return (
      <NextLink href={href} {...props} suppressHydrationWarning>
        {children}
      </NextLink>
    );

  return (
    <a {...props} suppressHydrationWarning>
      {children}
    </a>
  );
};

export const Link = GenericSlot({ as: Base, theme: LinkTheme, render });
