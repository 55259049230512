import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { A11y, Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide, SwiperSlideProps } from 'swiper/react';
import { HighlightedArticles } from './HighlightedArticles';

import { getUrl } from '@/utils/getUrl';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import Tracking from 'base/components/TrackingComponent';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

interface HighlightedArticle {
  label?: React.ReactNode;
  headline?: React.ReactNode;
  link?: LinkProps;
}

export interface StandaloneHighlightedArticlesProps
  extends StandaloneComponentProps<
    typeof HighlightedArticles,
    {
      $swiper?: SwiperProps;
      $slide?: SwiperSlideProps;
    }
  > {
  articles?: HighlightedArticle[];
  showBlip?: boolean;
  isLoading?: boolean;
}

export const StandaloneHighlightedArticles: StandaloneComponent<StandaloneHighlightedArticlesProps> = ({
  articles,
  showBlip,
  isLoading,
  ...props
}) => {
  if (isLoading) {
    return <HighlightedArticles.Skeleton />;
  }

  if (!articles?.length) {
    return null;
  }

  return (
    <Tracking>
      <HighlightedArticles {...props}>
        <Swiper
          className="w-full"
          modules={[Navigation, Autoplay, A11y]}
          grabCursor
          autoplay
          loop
          speed={2500}
          {...props.options?.$swiper}
        >
          {articles.map(({ label, headline, link }, index) => {
            const ctsUrl = getUrl(link?.href);
            return (
              <SwiperSlide key={index} {...props.options?.$slide}>
                <Link
                  options={{ className: 'cts-tracking-object' }}
                  content={
                    <HighlightedArticles.Item data-show-blip={showBlip}>
                      {showBlip && <HighlightedArticles.Blip />}
                      <HighlightedArticles.Label>{label}</HighlightedArticles.Label>
                      <HighlightedArticles.Headline>{headline}</HighlightedArticles.Headline>
                      <HighlightedArticles.Icon name="sliderArrow" />
                    </HighlightedArticles.Item>
                  }
                  {...link}
                  {...withTrackingAttributes({
                    category: 'menu',
                    name: `${headline}`,
                    url: ctsUrl ? `${ctsUrl}` : '',
                    extraInfo: [{ type: 'highlighted_articles_menu' }],
                    placement: `${index + 1}`,
                  })}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </HighlightedArticles>
    </Tracking>
  );
};
