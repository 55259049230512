const plugin = require('tailwindcss/plugin');

const withMinWidthBreakpoint = (value) => `(min-width: ${value})`;
const withWidthGreaterThenBreakpoint = (value) => `(width > ${value})`;

const gridSystemPlugin = (props) => {
  const { screens } = props ?? {};
  const commonProps = ['maxWidth', 'minWidth', 'width'];
  const columns = Array(12).fill(0);

  const gridSystem = Object.entries(screens).reduce(
    (result, screen) => {
      const [key, values] = screen ?? {};
      const { minWidth, maxContentWidth, maxArticleWidth, gap, margin } = values ?? {};

      if (minWidth) {
        result.screens[key] = minWidth;
      }

      if (maxContentWidth) {
        const maxContentWidthKey = `content-${key}`;
        commonProps.forEach((prop) => {
          result[prop] = {
            [maxContentWidthKey]: maxContentWidth,
            ...result[prop],
          };
        });

        result.containers[maxContentWidthKey] = withMinWidthBreakpoint(maxContentWidth);
      }

      if (maxArticleWidth) {
        const maxArticleWidthKey = `article-${key}`;
        commonProps.forEach((prop) => {
          result[prop] = {
            [maxArticleWidthKey]: maxArticleWidth,
            ...result[prop],
          };
        });

        result.containers[maxArticleWidthKey] = withMinWidthBreakpoint(maxArticleWidth);
      }

      if (gap && margin) {
        result.gap[key] = gap;
        result.margin[key] = margin;

        const maxViewportWidth = maxContentWidth ? `min(100vw, ${maxContentWidth})` : '100vw';
        const maxGridWidth = `${maxViewportWidth} - ${margin} * 2`;
        const columnWidth = `(${maxGridWidth} - ${gap} * 11) / 12`;

        columns.forEach((_, index) => {
          const columnsWidth = `${columnWidth} * ${index + 1}`;
          const gapsWidth = `${gap} * ${index}`;
          const sectionWidth = `calc(${columnsWidth} + ${gapsWidth})`;

          const commonKey = `col-${key}-${index + 1}`;
          const containersKey = `col-${key}-${index + 2}`;

          commonProps.forEach((prop) => {
            result[prop][commonKey] = sectionWidth;
          });

          result.containers[containersKey] = withWidthGreaterThenBreakpoint(sectionWidth);
        });
      }

      return result;
    },
    {
      containers: {},
      gap: {},
      margin: {},
      maxWidth: {},
      minWidth: {},
      screens: {},
      width: {},
    },
  );

  return plugin(() => {}, {
    theme: {
      extend: gridSystem,
    },
  });
};

module.exports = gridSystemPlugin;
