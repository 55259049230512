import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  defaultVariants: {
    size: 'small',
  },
});

const Button = tw.theme({
  base: [
    'active:ring-0',
    'border',
    'duration-200',
    'ease-in-out',
    'focus:outline-none',
    'focus:ring-4',
    'hover:ring-0',
    'inline-flex',
    'items-center',
    'justify-center',
    'outline-none',
    'ring-0',
    'rounded-full',
    'transition-all',
  ],
  variants: {
    colors: {
      primary: [
        'bg-primary-700',
        'hover:bg-primary-600',
        'active:bg-primary-800',
        'focus:ring-primary-200',
        'border-primary-700',
        'text-white',
      ],
      secondary: [
        'bg-white',
        'hover:bg-primary-100',
        'focus:ring-primary-200',
        'active:bg-primary-200',
        'border-primary-700',
        'text-primary-700',
      ],
    },
    size: {
      small: ['px-5', 'py-2', 'text-headline-xs', 'gap-1.5'],
      medium: ['px-6', 'py-2.5', 'text-headline-sm', 'gap-2.5'],
      large: ['px-6', 'py-3', 'text-headline-sm', 'gap-2.5'],
    },
    fill: {
      true: ['w-full'],
    },
    icon: {
      true: ['w-auto', 'aspect-square', 'px-0'],
    },
  },
  defaultVariants: {
    colors: 'primary',
    size: 'medium',
  },
});

export default Object.assign(Button, { Icon });
