import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = tw.theme({
  extend: ButtonTheme,
  variants: {
    size: {
      large: 'my-4',
    },
  },
});

export default Object.assign({}, { Button });
