import { ArticleTeaserDefault } from '@/components/ArticleTeaser/Default';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { getCategoryUrl } from '@/utils/getCategoryUrl';

export const LabradorArticleTeaserDefault: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();

  const { category, subtitle, kicker, title, publishedUrl, displayProminentTagOnTeaser } = data ?? {};
  const { showKicker, isNativeAd, nativeAdLabel, teaserIndex, showSubtitle, showCategory } = meta ?? {};

  const labradorImage = findFirstOfType(descendants, 'labradorImage');
  const { imageUrl, url, alt } = labradorImage?.data ?? {};
  const imageSrc = imageUrl?.[device] || url;

  const showKickerOnDevice = showKicker?.[device];
  const showSubtitleOnDevice = showSubtitle?.[device];
  const showCategoryOnDevice = showCategory?.[device];

  const isPriority = teaserIndex <= 10;
  const prominentTag = displayProminentTagOnTeaser ? data.prominentTag : undefined;
  const displayCategory = isNativeAd ? nativeAdLabel || category : category;

  return (
    <ArticleTeaserDefault
      caption={showCategoryOnDevice && (prominentTag || displayCategory)}
      description={showSubtitleOnDevice && subtitle}
      kicker={showKickerOnDevice && kicker}
      headline={title}
      links={{
        article: { href: publishedUrl },
        caption: { href: getCategoryUrl(data, meta) },
      }}
      image={{
        src: imageSrc,
        alt,
        options: { priority: isPriority },
      }}
      options={mergeOptions(data.options, {
        variant: meta.horizontal ? 'horizontal' : 'vertical',
      })}
    />
  );
};
