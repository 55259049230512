import { OcelotConfig } from '@/types/ocelotConfig';
import { NestedKeys, PathType } from '@/types/utils';
import { logger } from '@/utils/logger';
import mergeConfig from 'modules/config/merge.config';
import { deepClone } from 'typesafe-utils';

export type OcelotConfigKeys = NestedKeys<OcelotConfig>;

export const getOcelotConfig = <K extends OcelotConfigKeys | ''>(key: K): PathType<OcelotConfig, K> => {
  // We create a copy of the config as to not cause stupid reference errors
  const mergeConfigCopy = deepClone(mergeConfig);
  if (!key) return mergeConfigCopy as PathType<OcelotConfig, K>;

  try {
    return key.split('.').reduce((item: any, key: string) => item?.[key], mergeConfigCopy);
  } catch (error) {
    if (process.env.CONFIG_DEBUG === 'true') {
      logger.error(`Failed to get ocelot config key: ${key}`, error);
    }

    return undefined as unknown as PathType<OcelotConfig, K>;
  }
};
