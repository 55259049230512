import { tw } from '@/utils/tw';

const Link = tw.theme({
  base: '',
  variants: {
    cover: {
      true: ['absolute', 'inset-0', 'z-1', 'flex', 'h-full', 'w-full', 'items-center', 'justify-center'],
    },
  },
});

export default Link;
