import { ContactEditorial } from '@/components/ContactEditorial';
import { LabradorComponent } from '@/types/component';

export const LabradorContactEditorial: LabradorComponent = ({ data, type, meta, descendants, ...props }) => {
  return (
    <ContactEditorial
      headline={data.headline}
      caption={data.caption}
      label={data.label}
      submitButton={data.submitButton}
      successDialog={data.successDialog}
      errorDialog={data.errorDialog}
      {...props}
    />
  );
};
