import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { VideoReels } from './VideoReels';

export interface StandaloneVideoReelsProps extends StandaloneComponentProps<typeof VideoReels> {
  heading: React.ReactNode;
  placementId: string;
}

export const StandaloneVideoReels: StandaloneComponent<StandaloneVideoReelsProps> = ({
  heading,
  placementId,
  ...props
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [{ isVideoReelLoaded }, appStateDispatcher] = useAppState();

  const [showElement, setShowElement] = useState(true);
  useEffectOnce(() => {
    /**
      It is important that the reels are only loaded once in a page.
      Ref - https://docs.jwplayer.com/platform/docs/manage-placements#deploy-a-placement
      So reels should not be shown for lazy loaded second natives.
    */
    const scriptInjected = Array.from(videoRef.current?.childNodes ?? []).some((node) => node.nodeName === 'SCRIPT');
    if (scriptInjected) {
      return;
    }

    if (isVideoReelLoaded) {
      setShowElement(false);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://cdn.jwplayer.com/v2/sites/3VFCRfhD/placements/${placementId}/embed.js`;
    videoRef.current?.appendChild(script);

    appStateDispatcher({
      isVideoReelLoaded: true,
    });
  });

  const reelsOpen = useCallback(() => {
    const videoElements = document.querySelectorAll('[id^="video-"]');
    videoElements.forEach((element) => {
      window.jwplayer(element.id).setFloating(false);
    });
  }, []);

  if (!placementId || !showElement) {
    return null;
  }

  return (
    <>
      <VideoReels {...props}>
        <VideoReels.Heading>{heading}</VideoReels.Heading>
        <VideoReels.Element
          id={`reels-${placementId}`}
          ref={videoRef}
          onClick={reelsOpen}
          {...withTrackingAttributes({
            category: 'teaser',
            name: 'jw_reels_widget',
            extraInfo: [{ type: 'video_reel_jw', jw_placement_id: placementId }],
          })}
        />
      </VideoReels>
    </>
  );
};
