import { ContentProps } from '@/types/content';

export const getCategoryUrl = (data: ContentProps['data'], meta: ContentProps['meta']) => {
  const { category, categoryUrl, prominentTag } = data ?? {};
  const { isNativeAd, nativeAdLabel } = meta ?? {};

  if (isNativeAd) {
    return `etikett/${(nativeAdLabel || ('' as string)).toLocaleLowerCase()}`;
  }

  if (prominentTag) {
    return `etikett/${prominentTag}`;
  }

  return categoryUrl || category;
};
