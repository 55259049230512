import { RecommendedArticles } from '@/components/RecommendedArticles';
import { useAppState } from '@/hooks/useAppState';
import useLazyCallback from '@/hooks/useLazyCallback';
import { LabradorComponent } from '@/types/component';
import { logger } from '@/utils/logger';
import { RecommendedArticlesData } from 'base/components/RecommendedArticles';
import { createArticleDataStructure } from 'base/components/RecommendedArticles/helpers';
import { getRecommendedArticles, readyToFetch } from 'base/components/RecommendedArticles/services';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { useCallback, useState } from 'react';
import { isNotTrue, isNull } from 'typesafe-utils';

export const LabradorRecommendedArticles: LabradorComponent = () => {
  const [{ pageId }] = useAppState();

  const [articles, setArticles] = useState<RecommendedArticlesData | null>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchArticles = useCallback(async () => {
    setIsLoading(true);
    const isReady = await readyToFetch();

    if (!isReady) {
      return setArticles(null);
    }

    const articlesData = await getRecommendedArticles(String(pageId)).catch(logger.catch);

    if (!articlesData) {
      return setArticles(null);
    }

    const transformedData = createArticleDataStructure(articlesData);
    setArticles(transformedData);
    setIsLoading(false);
  }, [pageId]);

  const ref = useLazyCallback<HTMLDivElement>(fetchArticles, {
    rootMargin: '1000px',
    root: null,
  });

  if (isNotTrue(getOcelotConfig('features.recommendedArticles.enabled')) || isNull(articles)) return null;

  return articles?.length > 0 ? <RecommendedArticles isLoading={isLoading} articles={articles} /> : <div ref={ref} />;
};
