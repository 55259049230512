import { ButtonTheme } from '@/components/Button/theme';
import { TimerTheme } from '@/components/Timer/theme';
import { tw } from '@/utils/tw';

const Button = ButtonTheme;
const Timer = TimerTheme;

const CourseCountdown = tw.theme({
  slots: {
    base: ``,
    logo: ``,
    description: ``,
  },
});

export default Object.assign(CourseCountdown, { Button, Timer });
