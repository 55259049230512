import { Strossle } from '@/components/Strossle';
import { LabradorRecommendedArticles } from '@/components/labrador/RecommendedArticles';
import { LabradorSecondNativeArticle } from '@/components/labrador/SecondNativeArticle';
import { useClassName } from '@/styles/Article';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { brandConfig } from '../RecommendedArticles/config';

type RecommendationSectionElement =
  | 'secondNativeArticle'
  | 'recommendedArticles'
  | 'strossle'
  | 'affiliateGallery'
  | 'moreRecommendedArticles';

const RECOMMENDATION_SECTION_ELEMENTS_FALLBACK: RecommendationSectionElement[] = [
  'secondNativeArticle',
  'recommendedArticles',
  'strossle',
  'affiliateGallery',
  'moreRecommendedArticles',
];

const getRecommendationSectionElements = (): RecommendationSectionElement[] => {
  // in future it will be ordered dynamically based on user preferences

  return RECOMMENDATION_SECTION_ELEMENTS_FALLBACK;
};

export interface ArticleRecommendedProps extends ComponentProps<'div'> {
  affiliateGallery?: JSX.Element;
}

export const ArticleRecommendedComponent: Component<ArticleRecommendedProps> = ({ affiliateGallery, ...props }) => {
  const showMoreRecommendedArticles = brandConfig.showMoreBelowArticle;

  const elements = getRecommendationSectionElements();

  const components = elements
    .map((elementType) => {
      switch (elementType) {
        case 'secondNativeArticle':
          return getOcelotConfig('features.secondArticle.enabled') ? (
            <LabradorSecondNativeArticle key="secondNativeArticle" type={''} meta={{}} data={{}} descendants={[]} />
          ) : null;
        case 'recommendedArticles':
          return (
            <LabradorRecommendedArticles key="recommendedArticles" type={''} meta={{}} data={{}} descendants={[]} />
          );
        case 'strossle':
          return <Strossle key="strossle" />;
        case 'affiliateGallery':
          return affiliateGallery && { ...affiliateGallery, key: 'affiliateGallery' };
        case 'moreRecommendedArticles':
          return (
            showMoreRecommendedArticles && <div key="moreRecommendedArticles" id="more-recommended-articles-portal" />
          );
        default:
          return undefined;
      }
    })
    .filter(Boolean) as JSX.Element[];

  const className = useClassName('recommended', props);

  return <div {...mergeProps({ className }, props)}>{components}</div>;
};
