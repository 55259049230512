import { getLabradorApiUrl } from '@/utils/getLabradorApiUrl';
import { getSite } from '@/utils/getSite';
import { http } from '@/utils/http';

export const fetchArticlesData = async (ids: string | string[]) => {
  const articleIds = Array.isArray(ids) ? ids : [ids];

  const labradorApiParams = {
    query: `id:( ${articleIds.join(' OR ')}  )`,
  };

  const opts = {
    params: labradorApiParams,
    auth: {
      username: process.env.OCELOT_LABRADOR_BASIC_AUTH_USERNAME || '',
      password: process.env.OCELOT_LABRADOR_BASIC_AUTH_PASSWORD || '',
    },
  };

  if (!getSite()) throw new Error('Missing env: OCELOT_SITE');

  const apiBaseUrl = getLabradorApiUrl();

  const apiEndpoint = '/article';
  const fetchUrl = `${apiBaseUrl}${apiEndpoint}`;

  try {
    const response = await http.get(fetchUrl, opts);
    return response?.data;
  } catch {
    throw new Error('Could not load article');
  }
};
