import { StandaloneComponent } from '@/types/component';
import { getSite } from '@/utils/getSite';
import { getOcelotConfig } from 'lib/configs/ocelot';
import Script from 'next/script';
import { useEffect } from 'react';

export const StandaloneConsent: StandaloneComponent = () => {
  const site = getSite();
  const consentVersion = `2.3.14${['svenskdam', 'hant'].includes(`${site}`) ? `${site}` : ''}`;
  const consentPath = `/common/js/consent`;
  const componentVersion = '05';
  const cacheBuster = `v=${consentVersion}-${componentVersion}`;
  const oneTrustId = getOcelotConfig('cmp.onetrust_id');

  const isVercelPreview = Boolean(process?.env?.VERCEL);

  useEffect(() => {
    window.onetrustId = oneTrustId;
  }, []);

  if (process.env.CONSENT_DISABLED === 'true') {
    return null;
  }

  return (
    <>
      {/* Consent Stub containing tcfapi */}
      <Script src="https://cdn.cookielaw.org/consent/tcf.stub.js" strategy="beforeInteractive" />

      {/* CMP - OneTrust consent */}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        strategy="beforeInteractive"
        data-document-language="true"
        data-domain-script={`${oneTrustId}`}
      />

      {/* Lock bg-scroll for CMP banner */}
      {!isVercelPreview && (
        <Script src={`${consentPath}/consent-conf.js?${cacheBuster}`} strategy="beforeInteractive" />
      )}

      {/* Aller consent library */}
      <Script src={`${consentPath}/consent-${consentVersion}.js`} strategy="beforeInteractive" />
    </>
  );
};
