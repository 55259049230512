import { AuthorDetailsTheme } from '@/components/AuthorDetails/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { SoMe as StandaloneSoMe } from '@/components/SoMe';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: AuthorDetailsTheme });

const Base = $({ as: 'section', slot: 'base' });
const Caption = $({ as: 'div', slot: 'caption' });
const Description = $({ as: 'div', slot: 'description' });
const Email = $({ as: 'div', slot: 'email' });
const Headline = $({ as: 'h1', slot: 'headline' });
const Image = $({ as: StandaloneImage, theme: AuthorDetailsTheme.Image });
const SoMe = GenericSlot({ as: StandaloneSoMe, theme: AuthorDetailsTheme.SoMe });
const SoMeGroup = $({ as: 'div', slot: 'soMeGroup' });

export const AuthorDetails = Object.assign(Base, {
  Caption,
  Description,
  Email,
  Headline,
  Image,
  SoMe,
  SoMeGroup,
});
