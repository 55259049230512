import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const Button = tw.theme({
  extend: ButtonTheme,
  variants: {
    size: {
      medium: ['text-button-sm', 'md:text-button-md', '!h-7', 'md:h-11'],
    },
  },
});

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: ``,
    headline: `text-headline-xs sm:text-headline-sm`,
    footer: `[&:not(:first-child)]:mt-4 [&:not(:first-child)]:md:mt-6`,
  },
  variants: {
    size: {
      primary: {
        base: `gap-y-2`,
      },
    },
  },
});

export default Object.assign(Row, { Button });
