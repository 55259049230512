import { Button as StandaloneButton } from '@/components/Button';
import { RowTheme } from '@/components/Row/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: RowTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'header', slot: 'headline' });
const Caption = $({ as: 'div', slot: 'caption' });
const Footer = $({ as: 'footer', slot: 'footer' });
const Button = GenericSlot({ as: StandaloneButton, theme: RowTheme.Button });

export const Row = Object.assign(Base, {
  Headline,
  Caption,
  Footer,
  Button,
});
