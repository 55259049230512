import NotAMemberModalImage from '@/allas/public/image/not-a-member-modal.png';
import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import useUserAuth from '@/hooks/useUserAuth';
import { mergeProps } from '@/utils/merge';
import { StandaloneSaveArticleButton } from 'base/components/SaveArticleButton';
import NextImage from 'next/image';

export const AllasStandaloneSaveArticleButton: typeof StandaloneSaveArticleButton = (props) => {
  const { logInUrl, signUpUrl } = useUserAuth();

  return (
    <StandaloneSaveArticleButton
      {...mergeProps(
        {
          options: { $button: { colors: 'secondary', size: 'large' } },
          modalContent: (
            <div className="relative flex flex-col-reverse items-center md:flex-row">
              <Icon options={{ size: 'medium', className: 'cursor-pointer absolute right-2 top-2' }} name="close" />
              <NextImage src={NotAMemberModalImage} alt="" />
              <div className="flex max-w-90 flex-col items-center gap-y-2 px-6 pb-5 pt-8 md:gap-y-3 md:px-10 md:py-0">
                <span className="mb-1 text-center text-body-md">
                  För att spara den här artiklen så måste du vara inloggad
                </span>

                <Button href={logInUrl} options={{ rel: 'nofollow' }} content="Logga in på ditt konto" />

                <span className="text-body-md">eller</span>

                <Button href={signUpUrl} options={{ colors: 'secondary', rel: 'nofollow' }} content="Skapa ett konto" />
              </div>
            </div>
          ),
        },
        props,
      )}
    />
  );
};
