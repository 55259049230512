import { Button as StandaloneButton } from '@/components/Button';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { useClassName } from '@/styles/KlarnaPayment';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { GenericSlot } from 'base/components/GenericSlot';

const Button = GenericSlot({ as: StandaloneButton, theme: KlarnaPaymentTheme.Button });
const GenericComponent = GenericComponentFactory({ useClassName });

const Section = GenericComponent({ as: 'div', styles: { key: '' } });
const Content = GenericComponent({ as: 'div', styles: { key: 'content' } });

export const KlarnaPayment = Object.assign(Section, {
  Content,
  Button,
});
