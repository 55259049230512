import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    headline: ['text-headline-xs', 'sm:text-headline-sm'],
    group: ['gap-1.5'],
    description: ['sm:mt-0.5'],
  },
});

const RecommendedArticles = tw.theme({
  extend: BaseRecommendedArticlesTheme,
  slots: {
    base: ['bg-yellow-600', 'wings-yellow-600', 'py-4', 'md:py-6', 'px-3'],
    headline: ['mb-3', 'sm:mb-3.5', 'text-headline-xs', 'sm:text-headline-sm'],
    list: ['gap-3', 'md:gap-6'],
  },
});

export default Object.assign(RecommendedArticles, {
  ArticleTeaser,
  Skeleton: BaseRecommendedArticlesTheme.Skeleton,
});
