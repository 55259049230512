import { findAllOfType, findFirstOfType } from '../content-operations/findBy';
import { Content } from '../types';

/**
 * Extracts the byline names from the page data.
 *
 * Searches for article header in the children of page data.
 * If found, it retrieves all bylines and constructs an array of full names.
 *
 * @param pageData - The structured page data
 * @returns string[] - An array with full byline names
 */
export const getBylineNames = (pageData: Content): string[] => {
  const articleHeaderContent = findFirstOfType(pageData.children, 'articleHeader');
  const articleBylines = articleHeaderContent ? findAllOfType(articleHeaderContent.children, 'byline') : [];
  return articleBylines.map((byline) => `${byline.data.firstName || ''} ${byline.data.lastName || ''}`);
};
