import { BrandOcelotConfig } from '@/types/ocelotConfig';

const AllasConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:qjoitett5ht',
    },
    placeholderImage: '/image/allas-logo.svg',
  },
  analytics: {
    brandName: 'allas',
    google: {
      measurement_id: 'G-85B3GFWFNJ',
      dimensions: {
        page_id: '1',
        mobile_website: '2',
      },
      viewId: 141882424,
      hostname: 'www.allas.se',
    },
    parsely: {
      siteId: 'allas.se',
    },
    sifo: {
      propertyId: '45951e4a40e4495f87672b142f49cb90',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_allas',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_allas.js',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'csMatchingKey', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    strossle: {
      enabled: true,
      id: 'ad6f656a-60f6-42b5-957b-8da8b8b69e7b',
    },
    login: {
      enabled: true,
      brandName: 'allas__se',
    },
    secondArticle: {
      enabled: true,
    },
    keesingGames: {
      enabled: true,
      customerId: 'allas',
      scriptPath: 'https://web.keesing.com/pub/bundle-loader/bundle-loader.js',
      stylesPath: 'https://web.keesing.com/pub/config/allas/css/custom_client.css',
      frontPagePathname: '/korsord-och-spel',
    },
    trendingMenu: {
      label: 'Lästips:',
      tagPrefixes: ['nytt!'],
    },
  },

  seo: {
    schemaMarkup: {
      name: 'Allas',
      foundingDate: '1931-01-01',
      alternateName: ['Allas.se'],
      sameAs: [
        'https://sv.wikipedia.org/wiki/Allas',
        'https://www.wikidata.org/wiki/Q10405561',
        'https://www.pinterest.se/allas_se/',
        'https://www.youtube.com/channel/UCbhwNRc_wF3tpAkM2feMTFA',
        'https://sv-se.facebook.com/allasveckotidning/',
        'https://www.instagram.com/allas.se/',
      ],
      publishingPrinciples: '',
      verificationFactCheckingPolicy: '',
      correctionsPolicy: '',
      unnamedSourcesPolicy: '',
      missionCoveragePrioritesPolicy: '',
      webPages: ['/shop', '/om-oss', '/om-cookies', '/login', '/alla-amnen', '/nyhetsbrev', '/vara-skribenter'],
    },
  },
  ads: {
    exceptionPaths: {
      '^korsord-och-spel$': {
        aboveBody: true,
        extraStartCount: 5000,
        useHeights: false,
      },
      '^korsord-och-spel/\\S+$': {
        extraStartCount: 5000,
        useHeights: false,
      },
    },
    video: {
      clickToPlay: '0XDonXQi',
      autoPlay: 'a4kkgt0P',
    },
  },
  cmp: {
    onetrust_id: '95ac6b38-acd9-48c3-963e-b4c6af343834',
  },
  common: {
    redirects: [
      {
        from: '^korsord-och-spel/',
        to: 'korsord-och-spel',
      },
    ],
  },
};

export default AllasConfig;
