import type { LinkProps } from '@/components/Link';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';

import { ButtonTheme } from '@/components/Button/theme';
import { Link } from '@/components/Link';
import { Button } from './Button';

export type StandaloneButtonProps = StandaloneComponentProps<typeof Button> & {
  content?: React.ReactNode;
} & Omit<LinkProps, 'options' | 'content'>;

export const StandaloneButton: StandaloneComponent<StandaloneButtonProps> = ({ content, ...props }) => {
  if ('href' in props && props.href) {
    // @ts-expect-error: inferred `theme` type and `ref` type casting is not supported
    return <Link theme={ButtonTheme} content={content} {...props} />;
  }

  return (
    <Button type="button" {...props}>
      {content}
    </Button>
  );
};
