import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';

const Image = tw.theme({
  extend: ImageTheme,
  slots: {
    base: ['sm:w-45'],
    group: ['sm:row-span-5'],
  },
  variants: {
    variant: {
      default: {
        base: ['w-1/2', 'data-[fill]:aspect-square', 'rounded-full'],
        group: ['sm:max-w-45'],
      },
      alternative: {
        base: ['w-full', 'md:w-95', 'data-[fill]:aspect-[4/3]', 'rounded-none'],
        group: ['sm:max-w-95'],
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

// TODO: Update after refactoring `SoMe` to slots architecture
const SoMe = tw.theme({});

const AuthorDetails = tw.theme({
  slots: {
    base: ['w-full', 'grid', 'grid-cols-1', 'sm:grid-cols-[min-content_auto]'],
    caption: ['sm:col-start-2'],
    description: ['sm:col-start-2'],
    email: ['text-primary-700', 'text-headline-sm'],
    headline: [],
    soMeGroup: ['gap-x-3', 'flex', 'flex-row', 'sm:col-start-2'],
  },
  variants: {
    variant: {
      default: {
        base: ['bg-primary-100', 'rounded-lg', 'max-w-article-lg', 'p-3', 'gap-x-6'],
        caption: [],
        description: ['text-body-md', 'my-3'],
        email: ['sm:col-start-2'],
        headline: ['text-headline-md', 'mb-2', 'sm:col-start-2'],
        soMeGroup: ['my-1.5'],
      },
      alternative: {
        base: ['bg-white', 'p-6', 'gap-y-3', 'sm:p-0', 'sm:gap-x-6', 'sm:gap-y-6'],
        caption: ['order-1'],
        description: ['prose'],
        email: ['-mt-4', 'order-2'],
        headline: [
          'border-primary-900',
          'col-span-full',
          'text-headline-lg',
          'sm:border-b',
          'sm:order-first',
          'sm:pb-6',
        ],
        soMeGroup: ['order-3'],
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
