import { Button as StandaloneButton } from '@/components/Button';
import { SaveArticleButtonTheme } from '@/components/SaveArticleButton/theme';
import { useClassName } from '@/styles/SaveArticleButton';
import { PropsFromComponent } from '@/types/component';
import { GenericComponent } from 'base/components/GenericComponent';
import { GenericSlot } from 'base/components/GenericSlot';
import { SaveArticleButtonModalComponent } from './SaveArticleButton.Modal';

const Button = GenericSlot({ as: StandaloneButton, theme: SaveArticleButtonTheme.Button });

export type SaveArticleButtonProps = PropsFromComponent<typeof SaveArticleButton>;

export const SaveArticleButton = Object.assign(GenericComponent({ styles: { useClassName } }), {
  Modal: SaveArticleButtonModalComponent,
  Button,
});
