import { Article } from '@/components/Article';
import { useAppState } from '@/hooks/useAppState';
import { useRegisterArticleSessionView } from '@/hooks/useRegisterArticleSessionView';
import { LabradorComponent } from '@/types/component';
import { logger } from '@/utils/logger';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { useRef } from 'react';

export const LabradorArticle: LabradorComponent = ({ data, meta, descendants, ...props }) => {
  const [{ scrollArticlesAmount, accessLevel }] = useAppState();
  const articleRef = useRef<HTMLElement>(null);

  const { nativeAdLabel, isNativeAd, scrollArticleNumber, id } = meta ?? {};
  const { affiliateLabel, hasAffiliate, badgeData } = data ?? {};
  const { name: badgeName } = badgeData ?? {};
  const articleDisclosureLabels = [];

  useRegisterArticleSessionView(articleRef, id);

  if (isNativeAd) {
    if (!nativeAdLabel) {
      logger.warn('Missing nativeAdLabel in LabradorArticle, setting to default value.');
    }
    articleDisclosureLabels.push(nativeAdLabel ?? 'ANNONS');
  }

  if (hasAffiliate && affiliateLabel) {
    articleDisclosureLabels.push(affiliateLabel);
  }

  const isPaidArticle = accessLevel === 'purchase';

  const hide = getOcelotConfig('features.secondArticle.enabled')
    ? { badge: true }
    : {
        trendingMenu: isPaidArticle || Boolean(scrollArticleNumber),
        highlightedArticles: isPaidArticle || Boolean(scrollArticleNumber),
        badge: Boolean(scrollArticleNumber),
        recommendedArticles:
          isPaidArticle || Boolean(scrollArticlesAmount && scrollArticleNumber !== scrollArticlesAmount),
      };

  return (
    <Article
      hide={hide}
      badge={{ fragment: badgeName }}
      options={{
        ref: articleRef,
        ...(scrollArticleNumber &&
          getOcelotConfig('features.infinityScrollArticle.enabled') && {
            'data-cts-scroll-depth-item': 'second-native-article',
          }),
      }}
      {...{ descendants, articleDisclosureLabels }}
      {...props}
    />
  );
};
