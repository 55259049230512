import { ImageProps } from '@/components/Image';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import type { ReactNode } from 'react';
import { ArticleTeaser } from './ArticleTeaser';

export interface StandaloneArticleTeaserDefaultProps extends StandaloneComponentProps<typeof ArticleTeaser> {
  caption?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  headline?: ReactNode;
  kicker?: ReactNode;
  links?: {
    article?: OptionalLinkProps;
    caption?: OptionalLinkProps;
  };
  image: ImageProps;
}

export const StandaloneArticleTeaserDefault: StandaloneComponent<StandaloneArticleTeaserDefaultProps> = ({
  caption,
  description,
  footer,
  header,
  headline,
  image,
  kicker,
  links,
  ...props
}) => {
  return (
    <ArticleTeaser {...props}>
      {header && <ArticleTeaser.Header>{header}</ArticleTeaser.Header>}
      <OptionalLink
        {...links?.article}
        content={<ArticleTeaser.Image {...mergeProps(props?.options?.$image, image)} />}
      />
      <ArticleTeaser.Group>
        {caption && (
          <OptionalLink {...links?.caption} content={<ArticleTeaser.Caption>{caption}</ArticleTeaser.Caption>} />
        )}
        {headline && (
          <OptionalLink {...links?.article} content={<ArticleTeaser.Headline>{headline}</ArticleTeaser.Headline>} />
        )}
        {description && <ArticleTeaser.Description>{description}</ArticleTeaser.Description>}
        {kicker && <ArticleTeaser.Description>{kicker}</ArticleTeaser.Description>}
      </ArticleTeaser.Group>
      {footer && <ArticleTeaser.Footer>{footer}</ArticleTeaser.Footer>}
    </ArticleTeaser>
  );
};
