import { tw } from '@/utils/tw';
import BaseAuthorDetailsTheme from 'base/components/AuthorDetails/theme';

const { SoMe } = BaseAuthorDetailsTheme;

const Image = tw.theme({
  extend: BaseAuthorDetailsTheme.Image,
  variants: {
    variant: {
      default: {
        base: ['w-3/4', 'sm:w-45', 'data-[fill]:aspect-square', 'bg-transparent'],
      },
    },
  },
});

const AuthorDetails = tw.theme({
  extend: BaseAuthorDetailsTheme,
  variants: {
    variant: {
      default: {
        base: [
          'bg-white',
          'p-3',
          'gap-x-6',
          'm-6',
          'mb-0',
          'md:grid',
          'md:grid-cols-1',
          'md:grid-cols-[min-content_auto]',
        ],
        caption: ['mb-3', 'md:mb-1.5'],
        description: ['max-w-2xl', 'text-body-md', 'my-0'],
        email: ['text-logga-700', 'text-button-md'],
        headline: ['text-headline-sm', 'mt-6', 'md:mt-0', 'mb-1.5', 'md:mb-1'],
        soMeGroup: ['gap-x-3', 'my-6', 'md:my-3'],
      },
    },
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
