import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = tw.theme({
  extend: ButtonTheme,
  base: 'whitespace-nowrap',
  defaultVariants: {
    size: 'small',
  },
});

const Link = tw.theme({
  base: [],
  variants: {
    colors: {
      bar: ['text-white', 'lg:text-primary-800'],
      drawer: ['text-black'],
    },
    size: {
      bar: ['p-2.5'],
      controls: ['p-0'],
      drawer: ['py-2.5'],
    },
    variant: {
      bar: ['text-body-xs', 'inline-block'],
      drawerPrimary: ['text-headline-sm', 'block'],
      drawerSecondary: ['text-headline-sm', 'font-normal', 'block'],
    },
  },
  defaultVariants: {
    colors: 'bar',
    size: 'bar',
    variant: 'bar',
  },
});

export default Object.assign({}, { Button, Link });
