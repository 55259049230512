import { isNaN } from 'lodash-es';
import { isNotNull, isNotUndefined, isObject } from 'typesafe-utils';

export type JoinifyOptions<Fragment> = {
  separator?: string;
  prefix?: string;
  suffix?: string;
  filter?: ((fragment: Fragment) => boolean) | null;
};

/**
 * Joins an array of fragments into a single string with optional separator, prefix, suffix, and filter.
 *
 * @template Fragment
 * @param {Fragment[]} fragments - The array of fragments to join.
 * @param {JoinifyOptions<Fragment>} [options] - The options for joining the fragments.
 * @param {string} [options.separator=''] - The separator to use between fragments.
 * @param {string} [options.prefix=''] - The prefix to add before the joined string.
 * @param {string} [options.suffix=''] - The suffix to add after the joined string.
 * @param {(fragment: Fragment) => boolean} [options.filter=defaultFilter] - The filter function to apply to fragments.
 * @returns {string} The joined string.
 *
 * @example
 * joinify(['a', undefined, 'b']); // 'ab'
 * joinify(['a', undefined, 'b'], { separator: ',' }); // 'a,b'
 * joinify(['a', undefined, 'b'], { prefix: '[' }); // '[ab'
 * joinify(['a', undefined, 'b'], { suffix: ']' }); // 'ab]'
 * joinify(['a', undefined, 'b'], { filter: (fragment) => fragment !== 'b' }); // 'a'
 */
export const joinify = <Fragment>(fragments: Fragment[], options?: JoinifyOptions<Fragment>): string => {
  const { separator = '', prefix = '', suffix = '', filter = defaultFilter } = options ?? {};
  const filteredFragments = filter ? fragments.filter(filter) : fragments;
  return `${prefix}${filteredFragments.join(separator)}${suffix}`;
};

const defaultFilter = <Fragment>(fragment: Fragment): boolean =>
  isNotUndefined(fragment) && isNotNull(fragment) && !isNaN(fragment) && !isObject(fragment);
