import { getDomain } from '@/utils/getDomain';
import { getUrl } from '@/utils/getUrl';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { isProduction } from 'lib/labrador/utils';

export const getUserAuthUrl = () => {
  return isProduction ? 'https://login.allermedia.se' : 'https://login-stage.allermedia.se';
};

const getAuthUrlFactory = (path?: string) => (params?: Record<string, string>) => {
  const url = getUrl(path ?? '', getUserAuthUrl());
  const searchParams = new URLSearchParams(params ?? { return: getDomain(false) });

  if (!url) {
    throw Error('Unable to get user auth url');
  }

  url.search = searchParams.toString();

  return url.href;
};

export const getUserLogInUrl = getAuthUrlFactory();

export const getUserSignUpUrl = getAuthUrlFactory('/nytt-konto');

export const getUserPageUrl = getAuthUrlFactory('/mina-sidor');

export const getUserLogOutUrl = getAuthUrlFactory(`/logout/${getOcelotConfig('features.login.brandName')}`);

export const getUserPasswordlessUrl = getAuthUrlFactory('/account/passwordless');

export const getPasswordResetPageUrl = getAuthUrlFactory('/aterstall-losenord');
