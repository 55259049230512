import { logger } from '@/utils/logger';

export const withSerializationGuard = (data: object) => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch {
    logger.catch('Serialization guard failed', data);
    return {};
  }
};
