import { ArticleDisclosureLabelTheme } from '@/components/ArticleDisclosureLabel/theme';
import { mergeProps } from '@/utils/merge';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';
import { GenericSlot } from 'base/components/GenericSlot';
import useWindowScrollDirection from 'lib/hooks/useWindowScrollDirection';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const ArticleDisclosureLabel = GenericSlot({
  as: 'div',
  theme: ArticleDisclosureLabelTheme,
  render: function Render({ props, children }) {
    const [topOffset, setTopOffset] = useState<number>(0);
    const scrollDirection = useWindowScrollDirection({ initialDirection: 'up' });

    useEffectOnce(() => {
      const menu = document.getElementById('menu');

      if (!menu) return;

      const resizeObserver = new ResizeObserver((entries) => {
        setTopOffset(entries?.[0]?.contentRect?.height ?? 0);
      });

      resizeObserver.observe(menu);

      return () => {
        resizeObserver.unobserve(menu);
      };
    });

    return (
      <div
        data-scroll-direction={scrollDirection}
        {...withSafeInnerHTML(children)}
        {...mergeProps(
          {
            style: {
              top: topOffset,
            },
          },
          props,
        )}
      >
        {withNonHTMLChildren(children)}
      </div>
    );
  },
});
