import AllasLogo from '@/allas/public/image/allas-logo.svg';
import { Icon } from '@/components/Icon';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneNavigation } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationBarMenu } from 'base/components/Navigation/utils/getNavigationBarMenu';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import { getNavigationSoMeMenu } from 'base/components/Navigation/utils/getNavigationSoMeMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import useUserAuth, { UserAuthProps } from 'lib/hooks/useUserAuth';

const logo = <AllasLogo />;

const menu = getNavigationBarMenu({
  links: [
    { href: '/livsoden', content: 'Livsöden' },
    { href: '/tradgard', content: 'Trädgård' },
    { href: '/relationer', content: 'Relationer' },
    { href: '/handarbete', content: 'Handarbete' },
    { href: '/video', content: 'Video' },
    { href: '/bloggar', content: 'Bloggar' },
    { href: '/horoskop', content: 'Horoskop' },
  ],
});

type GetDrawerProps = Pick<UserAuthProps, 'isUserLoggedIn' | 'logInUrl' | 'logOutUrl' | 'myPageUrl'>;

const getDrawer = ({ isUserLoggedIn, logInUrl, logOutUrl, myPageUrl }: GetDrawerProps) =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          [
            { href: '/livsoden', content: 'Livsöden' },
            { href: '/etikett/läsarberättelser', content: 'Livsberättelser' },
          ],
          { href: '/halsa', content: 'Hälsa' },
          { href: '/relationer', content: 'Relationer' },
          { href: '/noveller', content: 'Noveller' },
          { href: '/handarbete', content: 'Handarbete' },
          { href: '/video', content: 'Video' },
          { href: '/bloggar', content: 'Bloggar' },
        ],
      },
      {
        links: [
          [
            { content: 'Hem & trädgård', as: 'span' },
            { href: '/tradgard', content: 'Trädgård' },
            { href: '/hushall', content: 'Hushåll' },
            { href: '/ekonomi', content: 'Ekonomi' },
            { href: '/mat-och-dryck', content: 'Mat & dryck' },
            { href: '/etikett/antik och auktion', content: 'Loppis & antikt' },
            { href: '/diy', content: 'DIY - gör det själv' },
          ],
        ],
      },
      {
        links: [
          [
            { content: 'Underhållning', as: 'span' },
            {
              href: '/korsord-och-spel',
              content: 'NYTT! Korsord',
              tracking: {
                name: 'NYTT! Spel',
                extraInfo: [{ type: 'main_navigation_menu', campaign: 'keesing_games' }],
              },
            },
            { href: '/noje', content: 'Nöje' },
            { href: '/tv', content: 'TV' },
            { href: '/horoskop', content: 'Horoskop' },
          ],
        ],
      },
      {
        links: [
          [
            { content: 'Om oss', as: 'span' },
            { href: '/om-oss', content: 'Om oss' },
            { href: '/kontakta-oss', content: 'Kontakta oss' },
            { href: '/tidningar-hos-allas-se', content: 'Tidningar hos Allas.se' },
            { href: '/nyhetsbrev', content: 'Nyhetsbrev' },
            { href: '/om-cookies', content: 'Om cookies' },
          ],
          isUserLoggedIn
            ? { href: logOutUrl, content: 'Logga ut', options: { rel: 'nofollow' } }
            : { href: logInUrl, content: 'Skapa konto', options: { rel: 'nofollow' } },
          {
            href: myPageUrl,
            content: (
              <div className="flex items-center gap-1">
                Mina Inställningar
                <Icon name="externalLink" />
              </div>
            ),
            options: {
              rel: 'nofollow',
              className: isUserLoggedIn ? '' : 'hidden',
            },
          },
          {
            href: '',
            content: 'Hantera preferenser',
            options: {
              onClick: (event: MouseEvent) => {
                event.preventDefault();
                showOneTrustConsent();
              },
            },
          },
          { href: '/alla-amnen', content: 'Alla ämnen' },
          { href: '/vara-skribenter', content: 'Våra skribenter' },
          { href: '/creative-studio', content: 'Creative studio' },
        ],
      },
    ],
    options: {
      $link: {
        variant: 'drawerPrimary',
      },
    },
  });

const soMe = getNavigationSoMeMenu({
  links: [
    {
      href: 'https://www.facebook.com/allasveckotidning',
      name: 'facebook',
      options: { 'aria-label': 'Länk till Allas Facebook-sida', colors: 'filled' },
    },
    {
      href: 'https://www.instagram.com/allas.se',
      name: 'instagram',
      options: { 'aria-label': 'Länk till Allas Instagram-sida', colors: 'filled' },
    },
    {
      href: 'https://www.youtube.com/channel/UCbhwNRc_wF3tpAkM2feMTFA',
      name: 'youtube',
      options: { 'aria-label': 'Länk till Allas YouTube-sida', colors: 'filled' },
    },
    {
      href: 'https://www.pinterest.se/allas_se',
      name: 'pinterest',
      options: { 'aria-label': 'Länk till Allas Pinterest-sida', colors: 'filled' },
    },
    {
      href: 'https://news.google.com/publications/CAAqBwgKMPKfiwswqd3qAg?hl=sv&gl=SE&ceid=SE%3Asv',
      options: {
        className: 'cts-tracking-object font-medium w-44 h-8',
        colors: 'filled',
        size: 'none',
        'aria-label': 'Länk till Google nyheter',
      },
      content: (
        <>
          <GoogleNewsIcon className="w-5" />
          Google nyheter
        </>
      ),
    },
  ],
});

type GetControlsProps = Pick<UserAuthProps, 'isUserLoggedIn' | 'logInUrl'>;

const getControls = ({ isUserLoggedIn, logInUrl }: GetControlsProps) => {
  return [
    <Navigation.Button
      key="prenumerera"
      href="https://prenumerera.se/tidningar/?utm_source=allas.se&utm_medium=menu&utm_campaign=prenumerera2023"
      target="_blank"
      options={{ colors: 'secondary', size: 'small' }}
      content="Prenumerera"
      aria-label="Länk till att prenumerera på Allas"
    />,
    <Navigation.Button
      key="login"
      href={isUserLoggedIn ? '/mina-sidor' : logInUrl}
      target={isUserLoggedIn ? '_self' : '_blank'}
      options={{ rel: 'nofollow', colors: 'primary', size: 'small' }}
      content={isUserLoggedIn ? 'Min Sida' : 'Logga in'}
      aria-label="Länk till att logga in på Allas"
    />,
    <Navigation.Button
      key="cookies"
      options={{
        onClick: showOneTrustConsent,
        className: 'border-0 text-logga-700',
        icon: true,
        colors: null,
      }}
      content={<Icon name="cookie" options={{ size: 'medium', onClick: showOneTrustConsent }} />}
      aria-label="Knapp för att öppna cookie-inställningar"
    />,
  ];
};

export const AllasStandaloneNavigation: typeof StandaloneNavigation = () => {
  const { isUserLoggedIn, logOutUrl, logInUrl, myPageUrl } = useUserAuth();

  const controls = getControls({ isUserLoggedIn, logInUrl });
  const drawer = getDrawer({ isUserLoggedIn, logInUrl, logOutUrl, myPageUrl });

  return (
    <StandaloneNavigation
      {...{
        menu,
        logo,
        drawer,
        soMe,
        controls,
      }}
    />
  );
};
