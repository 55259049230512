import { Link } from '@/components/Link';
import useUserAuth from '@/hooks/useUserAuth';
import useWindowDataLayer from '@/hooks/useWindowDataLayer';
import { useClassName } from '@/styles/Checkout';
import { Component, ComponentOptions, ComponentProps } from '@/types/component';
import { logger } from '@/utils/logger';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { SuccessPaymentResponse, onPaymentEvent } from 'base/components/KlarnaPayment';
import { createOrderRequest } from 'lib/services/payments';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseControllerProps, useForm } from 'react-hook-form';
import { isObject } from 'typesafe-utils';
import { Checkout } from './Checkout';

const paymentContainerId = 'payment-container';

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  newsletter: boolean;
};

type FormValidationRules = {
  [K in keyof FormInputs]?: UseControllerProps['rules'];
};

const formValidationRules: FormValidationRules = {
  firstName: {
    minLength: { value: 2, message: '* Fältet ska innehålla minst 2 tecken och högst 255 tecken' },
    maxLength: { value: 255, message: '* Fältet ska innehålla minst 2 tecken och högst 255 tecken' },
    required: '* Detta fält är obligatoriskt',
  },
  lastName: {
    minLength: { value: 2, message: '* Fältet ska innehålla minst 2 tecken och högst 255 tecken' },
    maxLength: { value: 255, message: '* Fältet ska innehålla minst 2 tecken och högst 255 tecken' },
    required: '* Detta fält är obligatoriskt',
  },
  email: {
    required: '* Var vänlig ange din e-postadress',
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: '* Vänligen ange en giltig e-postadress',
    },
  },
};

export interface CheckoutPaymentFormComponentProps extends ComponentProps<'form'> {
  form?: ComponentOptions<typeof Checkout.Form>;
}

export const CheckoutFormComponent: Component<CheckoutPaymentFormComponentProps> = ({ form, ...props }) => {
  const { push } = useRouter();
  const { alltIdUserCode, createPasswordlessAccount } = useUserAuth();
  const { push: dataLayerPush } = useWindowDataLayer();
  const checkoutForm = useForm<FormInputs>({
    defaultValues: { firstName: '', lastName: '', email: '', newsletter: false },
  });
  const formClassName = useClassName('form', props);

  const { $errors, $button, $checkbox, $textField, $payment, ...$baseForm } = form || {};

  const createAccount: SubmitHandler<FieldValues> = useCallback(
    async (formData) => {
      const { firstName, lastName, email } = formData;
      const subscription = Boolean(formData.newsletter);

      try {
        await createPasswordlessAccount({ firstName, lastName, email }, subscription);
      } catch (err) {
        logger.error(err);

        checkoutForm.setError('root.serverError', { message: '* Konto kan inte skapas' });
        return;
      }
    },
    [checkoutForm, createPasswordlessAccount],
  );

  const onKlarnaPaymentFailure = useCallback(
    async (e?: onPaymentEvent) => {
      checkoutForm.setError('root.serverError', { message: e?.message });
    },
    [checkoutForm],
  );

  const onKlarnaPaymentSuccess = useCallback(
    async (response: SuccessPaymentResponse) => {
      const orderDetails = await createOrderRequest(alltIdUserCode!, response.token);
      const price = orderDetails.order_amount / 100;
      dataLayerPush({
        event: 'purchase',
        ecommerce: {
          transaction_id: orderDetails.order_id,
          currency: orderDetails.purchase_currency,
          value: price,
          items: [{ price, item_name: orderDetails.order_key, item_id: orderDetails.order_key }],
        },
      });
      await push('/confirmation');
      window.scrollTo({ top: 0, behavior: 'instant' });
    },
    [push, dataLayerPush, alltIdUserCode],
  );

  return (
    <FormProvider {...checkoutForm}>
      <form
        onSubmit={checkoutForm.handleSubmit(createAccount)}
        {...mergeProps({ className: formClassName }, $baseForm)}
      >
        <Checkout.Form.Errors {...$errors}>
          {Object.values(checkoutForm.formState.errors.root ?? {}).map((error, index) => (
            <Checkout.Form.Errors.Message key={index}>
              {isObject(error) ? error.message : error}
            </Checkout.Form.Errors.Message>
          ))}
        </Checkout.Form.Errors>

        {!alltIdUserCode && (
          <>
            <Checkout.Form.TextField
              label={'Kontakt'}
              placeholder={'E-post'}
              name="email"
              rules={formValidationRules.email}
              {...$textField}
            />
            <Checkout.Form.TextField
              placeholder={'Förnamn'}
              name="firstName"
              rules={formValidationRules.firstName}
              {...$textField}
            />
            <Checkout.Form.TextField
              placeholder={'Efternamn'}
              name="lastName"
              rules={formValidationRules.lastName}
              {...$textField}
            />

            <Checkout.Form.Checkbox
              label="Jag vill ta del av inspiration och erbjudanden från MåBra."
              name="newsletter"
              {...$checkbox}
            />

            <Checkout.Form.Policy>
              För att ta del av utmaningen kommer ett konto skapas till dig. Dina uppgifter kommer hanteras enligt{' '}
              <Link
                content="Aller Medias personuppgiftspolicy"
                href="https://www.aller.se/integritetspolicy/"
                target="_blank"
                options={{ className: 'text-cherry-blossom-500 hover:underline' }}
              />
              .
            </Checkout.Form.Policy>

            <Checkout.Form.Button
              content="Fortsätt till betalning"
              options={mergeOptions(
                {
                  type: 'submit',
                  size: 'large',
                } as const,
                $button,
              )}
            />
          </>
        )}

        <Checkout.Form.Payment
          paymentContainerId={paymentContainerId}
          handleSuccess={onKlarnaPaymentSuccess}
          handleFailure={onKlarnaPaymentFailure}
          button={{ content: 'Betala', options: { size: 'large' } }}
          {...$payment}
        />
      </form>
    </FormProvider>
  );
};
