import { Link, LinkProps } from '@/components/Link';
import c from '@/styles/Navigation';
import { ClassNameProp, StandaloneComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getCurrentBrand } from '@/utils/getCurrentBrand';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { mergeProps } from '@/utils/mergeProps';

export interface NavigationLogoProps extends LinkProps {
  options?: LinkProps['options'] & {
    colors?: ClassNameProp<'default' | 'alternative'>;
    size?: ClassNameProp<'default' | 'alternative'>;
    variant?: ClassNameProp;
  };
}

export const NavigationLogoComponent: StandaloneComponent<NavigationLogoProps> = (props) => {
  const { colors, size, variant } = props?.options ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('logo_colors', colors);
  const sizeClassName = getPropStyles('logo_size', size);
  const variantClassName = getPropStyles('logo_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName);

  const { name } = getCurrentBrand();

  return (
    <Link
      {...mergeProps(
        {
          href: '/',
          options: {
            className: componentClassName,
            'aria-label': `Länk till ${name}s startsida`,
          },
        },
        props,
      )}
    />
  );
};
