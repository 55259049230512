import { Adunit } from '@/components/Adunit';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { getOcelotConfig } from 'lib/configs/ocelot';
import Head from 'next/head';
import Script from 'next/script';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Direkt } from './Direkt';

const loadDirekt = async (aId: string) => {
  const ifrSharedPostId = new URLSearchParams(window.location.search).get('ifr-dir-postId');
  const ifrUrlParams = new URLSearchParams({
    'ifr-dir-postId': ifrSharedPostId || '',
    articleUrl: encodeURIComponent(window.location.origin + window.location.pathname + window.location.search),
    customCss: getUrl('css/direkt.css')?.href as string,
    aId,
  });

  const response = await http.get('https://direkt.ifragasatt.se/load/?' + ifrUrlParams);

  const data = response?.data || {};
  if (!data?.src) {
    logger.error('Direkt: Could not load script');
    return null;
  }
  return data.src;
};

export interface StandaloneDirektProps extends StandaloneComponentProps<typeof Adunit> {
  adsUniqueId?: string;
}

export const StandaloneDirekt: StandaloneComponent<StandaloneDirektProps> = ({ adsUniqueId, ...props }) => {
  const { enabled, id, aId, headline, description, seoTitle, seoDescription } = getOcelotConfig('features.direkt');
  const [src, setSrc] = useState<string | null>(null);

  useEffectOnce(() => {
    if (enabled) loadDirekt(aId).then(setSrc);
  });

  if (!enabled) return <></>;

  return (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta name="title" content={seoTitle} key="metaTitle" />
        <meta name="description" content={seoDescription} key="metaDescription" />
      </Head>

      {src && (
        <Script
          id="show-direkt"
          dangerouslySetInnerHTML={{
            __html: src,
          }}
        />
      )}
      <Direkt {...props}>
        {adsUniqueId && <Adunit data={{ placementType: 'responsive', adIndex: 1, adsUniqueId }} />}

        <Direkt.Group>
          <Direkt.Blip />
          <Direkt.Heading>
            <span>{headline}</span>
            <Direkt.Label>Direkt</Direkt.Label>
          </Direkt.Heading>
        </Direkt.Group>
        <Direkt.Description>{description}</Direkt.Description>
        <div id={id} style={{ overflow: 'hidden' }} />

        {adsUniqueId && <Adunit data={{ placementType: 'responsive', adIndex: 2, adsUniqueId }} />}
      </Direkt>
    </>
  );
};
