import { Content } from '@/types/content';
import { joinify } from '@/utils/joinify';
import { stripHtml } from '@/utils/stripHtml';
import { withSerializationGuard } from '@/utils/withSerializationGuard';

interface Metadata {
  title: string;
  description: string;
  canonical: string;
  publishedDatetime: string;
  modifiedDatetime: string;
  category: string;
  ogTitle: string;
  ogDescription: string;
  ogType: 'article' | 'website' | 'video.other';
  ogImageUrl?: string;
}

/**
 * Generates metadata for a page based on the page data.
 *
 * @param {Content} pageData - The page data.
 * @returns {Metadata} The generated metadata object for the page.
 */
export const getMetadata = (pageData: Content): Metadata => {
  const { data, type } = pageData ?? {};

  const {
    title: pageTitle,
    seoTitle,
    titleSuffix,
    seoDescription,
    subtitle,
    canonical = '',
    publishedDatetime = '',
    modifiedDatetime = '',
    category = '',
    ogImageUrl,
  } = data ?? {};

  const titleWithSuffix = joinify([seoTitle || pageTitle], { suffix: titleSuffix });
  const description = stripHtml(seoDescription || subtitle);

  const ogType = ['article', 'website', 'video.other'].includes(type) ? (type as Metadata['ogType']) : 'website';
  const ogTitle = data.ogTitle || seoTitle || pageTitle || '';

  const ogDescription = stripHtml(data.ogDescription || description);

  return withSerializationGuard({
    title: titleWithSuffix,
    description,
    canonical,
    publishedDatetime,
    modifiedDatetime,
    ogTitle,
    ogDescription,
    ogType,
    category,
    ogImageUrl,
  });
};
