import { ButtonTheme } from '@/components/Button/theme';
import { TimerTheme } from '@/components/Timer/theme';
import { tw } from '@/utils/tw';

const Button = ButtonTheme;
const Timer = TimerTheme;

const ConfirmationTheme = tw.theme({
  slots: {
    base: `p-6`,
    title: ``,
    banner: ``,
    bannerHeader: ``,
    bannerParagraph: ``,
    content: ``,
  },
});

export default Object.assign(ConfirmationTheme, { Button, Timer });
