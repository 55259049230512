import { Content } from '@/types/content';
import { getFormattedDate } from '@/utils/getFormattedDate';
import { mergeDefined } from '@/utils/mergeDefined';
import { stripHtml } from '@/utils/stripHtml';
import { findAllOfType, findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { getBylineNames } from 'lib/labrador/utils/getBylineNames';
import { NewsArticle, WithContext } from 'schema-dts';
import { isTruthy } from 'typesafe-utils';
import {
  CustomSchemaMarkupConfig,
  getBylineUrl,
  getImageSrc,
  getPublishedUrl,
  publisher,
  publishingPrinciples,
  sameAs,
  unnamedSourcesPolicy,
} from './utils';

export const getArticlePageSchema = (
  pageData: Content,
  schema?: Partial<NewsArticle & CustomSchemaMarkupConfig>,
): WithContext<NewsArticle & CustomSchemaMarkupConfig> => {
  const { data, children } = pageData;
  const { publishedUrl, publishedDatetime, modifiedDatetime, hasAffiliate, category, tags } = data ?? {};

  const url = getPublishedUrl(publishedUrl);

  const articleHeaderContent = findFirstOfType(children, 'articleHeader');
  const articleHeaderImagesContent = findAllOfType(articleHeaderContent?.children ?? [], 'labradorImage');
  const articleHeaderImage = articleHeaderImagesContent.filter((content) => !content.meta.path.includes('byline'))[0];
  const imageId = articleHeaderImage?.data?.imageId;
  const articleHeaderImageSrc = imageId ? getImageSrc(imageId, 1200, 675) : '';
  const thumbnailUrl = imageId ? getImageSrc(imageId, 800, 450) : '';

  const datePublished = getFormattedDate(publishedDatetime);
  const dateModified = getFormattedDate(modifiedDatetime);

  const bylineNames = getBylineNames(pageData);

  const extraTags = [category?.includes('nyheter') && 'senaste-nytt', hasAffiliate && 'alleraffiliate'];
  const allTags = [...tags, ...extraTags].filter(isTruthy);

  const articlePageSchema: Partial<WithContext<NewsArticle & CustomSchemaMarkupConfig>> = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    isBasedOn: pageData.data.canonical || '',
    headline: stripHtml(pageData.data.title || ''),
    url,
    thumbnailUrl,
    image: {
      '@type': 'ImageObject',
      url: articleHeaderImageSrc,
    },
    datePublished,
    dateModified,
    articleSection: category || '',
    author: bylineNames.map((byline) => ({
      '@type': 'Person',
      name: byline,
      url: getBylineUrl(byline),
    })),
    creator: bylineNames,
    keywords: allTags,
    identifier: pageData.meta.uniqueId,
    publisher,
    sameAs,
    publishingPrinciples,
    unnamedSourcesPolicy,
  };

  return mergeDefined(articlePageSchema, schema);
};
