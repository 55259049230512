import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { logger } from '@/utils/logger';
import deepmerge from 'deepmerge';
import { ReactNode, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { isArray, isArrayEmpty, isNotFalse, isNotNull, isObject } from 'typesafe-utils';
import { NewsletterSignup, NewsletterSignupProps, NewsletterSignupProvider } from './NewsletterSignup';
import { NewsletterSignupButtonProps } from './NewsletterSignup.Button';
import { NewsletterSignupFieldsetProps } from './NewsletterSignup.Fieldset';
import { NewsletterSignupFieldsetCaptionProps } from './NewsletterSignup.Fieldset.Caption';
import { NewsletterSignupInputCheckboxProps } from './NewsletterSignup.InputCheckbox';
import { NewsletterSignupInputFieldProps } from './NewsletterSignup.InputField';
import { NewsletterSignupModalProps } from './NewsletterSignup.Modal';
import { NewsletterSignupModalContentProps } from './NewsletterSignup.Modal.Content';
import { standaloneNewsletterSignupDefaults } from './NewsletterSignup.constants';

const isEnabled = (value: any) =>
  isNotNull(value) && isNotFalse(value) && (!isArray(value) || (isArray(value) && !isArrayEmpty(value)));

type FieldsetOptions = {
  $fieldset?: NewsletterSignupFieldsetProps & {
    $caption: NewsletterSignupFieldsetCaptionProps;
    $checkboxes?: NewsletterSignupFieldsetProps;
    $fields?: NewsletterSignupFieldsetProps;
  };
};

type ModalOptions = {
  $modal?: NewsletterSignupModalProps & {
    $content?: NewsletterSignupModalContentProps;
  };
};

export interface StandaloneNewsletterSignupProps
  extends StandaloneComponentProps<typeof NewsletterSignup, FieldsetOptions & ModalOptions & {}> {
  error?: string;
  description?: ReactNode;
  footer?: ReactNode;
  headline?: ReactNode;
  inputs?: {
    checkboxes?: NewsletterSignupInputCheckboxProps[];
    fields?: NewsletterSignupInputFieldProps[];
  };
  successModal?: {
    content?: ReactNode;
    icon?: ReactNode;
  };
  submitButton?: Omit<NewsletterSignupButtonProps['$standalone'], 'options'>;
}

export const StandaloneNewsletterSignup: StandaloneComponent<StandaloneNewsletterSignupProps> = ({
  error,
  footer,
  description,
  headline,
  inputs,
  successModal,
  submitButton,
  options,
  ...props
}) => {
  const form = useForm();

  const successModalRef = useRef<HTMLDialogElement>(null);

  const {
    $button: buttonProps,
    $footer: footerProps,
    $description: descriptionProps,
    $fieldset,
    $headline: headlineProps,
    $inputCheckbox: inputCheckboxProps,
    $inputField: inputFieldProps,
    $rootError: serverErrorProps,
    $modal,
    colors,
    size,
    variant,
    ...baseProps
  } = options || {};

  const {
    $caption: captionFieldsetProps,
    $checkboxes: checkboxesFieldsetProps,
    $fields: fieldsFieldsetProps,
    ...fieldsetProps
  } = $fieldset || {};

  const { $content: modalContentProps, ...modalProps } = $modal || {};
  const { checkboxes, fields } = inputs || {};

  const onSubmitValid: NewsletterSignupProps['onSubmitValid'] = async (data, event) => {
    try {
      await baseProps?.onSubmitValid?.(data, event);
      successModalRef.current?.showModal();
    } catch (err: any) {
      logger.error(err);

      const responseStatus = err?.response?.status ?? err?.status;

      if (responseStatus) {
        form.setError('root.serverError', {
          message: error || standaloneNewsletterSignupDefaults.error,
          type: responseStatus,
        });
      } else {
        form.setError('root.random', {
          message: error || standaloneNewsletterSignupDefaults.error,
        });
      }
    }
  };

  const colorsBypass = colors === 'secondary' ? 'none' : colors;

  return (
    <NewsletterSignupProvider value={form}>
      <NewsletterSignup {...{ colors, size, variant }} {...baseProps} {...{ onSubmitValid }} {...props}>
        {isEnabled(headline) && (
          <NewsletterSignup.Headline {...{ colors, size, variant }} {...headlineProps}>
            {headline ?? standaloneNewsletterSignupDefaults.headline}
          </NewsletterSignup.Headline>
        )}

        {isEnabled(description) && (
          <NewsletterSignup.Description {...{ colors, size, variant }} {...descriptionProps}>
            {description ?? standaloneNewsletterSignupDefaults.description}
          </NewsletterSignup.Description>
        )}

        {isEnabled(checkboxes) && (
          <>
            <NewsletterSignup.Fieldset variant="alternative" {...fieldsetProps} {...checkboxesFieldsetProps}>
              {(checkboxes ?? standaloneNewsletterSignupDefaults?.inputs?.checkboxes)?.map((checkbox, index) => (
                <NewsletterSignup.InputCheckbox key={index} {...inputCheckboxProps} {...checkbox} />
              ))}
            </NewsletterSignup.Fieldset>

            <NewsletterSignup.Fieldset.Caption {...captionFieldsetProps} data-status="error">
              {
                Object.values(form.formState.errors).filter((error) => error?.ref?.type === 'checkbox')?.[0]
                  ?.message as ReactNode
              }
            </NewsletterSignup.Fieldset.Caption>
          </>
        )}

        {isEnabled(fields) && (
          <NewsletterSignup.Fieldset variant="default" {...fieldsetProps} {...fieldsFieldsetProps}>
            {(fields ?? standaloneNewsletterSignupDefaults?.inputs?.fields)?.map((field, index) => (
              <NewsletterSignup.InputField
                key={index}
                {...deepmerge<NewsletterSignupInputFieldProps>(
                  {
                    colors,
                    size: colors === 'secondary' ? 'alternative' : 'default',
                    variant,
                    $standalone: {
                      icon: 'error',
                      options: {
                        $caption: {
                          //@ts-expect-error: NewsletterSignup is based on legacy architecture
                          colors: colorsBypass,
                        },
                        ...inputFieldProps,
                      },
                    },
                  },
                  field,
                )}
              />
            ))}
          </NewsletterSignup.Fieldset>
        )}

        {isEnabled(submitButton) && (
          <NewsletterSignup.Button
            $standalone={{
              ...standaloneNewsletterSignupDefaults.submitButton,
              options: {
                // @ts-expect-error: NewsletterSignup is based on legacy architecture
                colors: colorsBypass,
                ...buttonProps,
              },
              ...submitButton,
            }}
            {...{ colors, size, variant }}
          />
        )}

        {Object.values(form.formState.errors.root ?? {}).map((error, index) => (
          <NewsletterSignup.RootError key={index}>{isObject(error) ? error.message : error}</NewsletterSignup.RootError>
        ))}

        {isEnabled(footer) && (
          <NewsletterSignup.Footer {...{ colors, size, variant }} {...footerProps}>
            {footer ?? standaloneNewsletterSignupDefaults.footer}
          </NewsletterSignup.Footer>
        )}

        {isEnabled(successModal) && (
          <NewsletterSignup.Modal
            ref={successModalRef}
            onClick={() => successModalRef.current?.close()}
            {...{ colors, size, variant }}
            {...modalProps}
          >
            {isEnabled(successModal?.icon) &&
              (successModal?.icon ?? standaloneNewsletterSignupDefaults.successModal?.icon)}

            {isEnabled(successModal?.content) && (
              <NewsletterSignup.Modal.Content {...{ colors, size, variant }} {...modalContentProps}>
                {successModal?.content ?? standaloneNewsletterSignupDefaults.successModal?.content}
              </NewsletterSignup.Modal.Content>
            )}
          </NewsletterSignup.Modal>
        )}
      </NewsletterSignup>
    </NewsletterSignupProvider>
  );
};
