import { DeepPartial } from '@/types/utils';
import { getLabradorApiUrl } from '@/utils/getLabradorApiUrl';
import { getLabradorBackendUrl } from '@/utils/getLabradorBackendUrl';
import { logger } from '@/utils/logger';
import Axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import axiosRetry from 'axios-retry';

type AxiosRequestMethod = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
) => Promise<R | null>;

type AxiosPayloadRequestMethod = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
) => Promise<R | null>;

declare module 'axios' {
  interface AxiosRequestConfig {
    skipInterceptors?: DeepPartial<{
      request: {
        fulfilled: boolean;
        rejected: boolean;
      };
      response: {
        fulfilled: boolean;
        rejected: boolean;
      };
    }>;
  }

  export interface AxiosInstance {
    <T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R | null>;
    <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R | null>;

    get: AxiosRequestMethod;
    delete: AxiosRequestMethod;
    head: AxiosRequestMethod;
    options: AxiosRequestMethod;
    post: AxiosPayloadRequestMethod;
    put: AxiosPayloadRequestMethod;
    patch: AxiosPayloadRequestMethod;
  }
}

const timeout = Number(process.env.AXIOS_TIMEOUT) || 10000;
const isDebugEnabled = process.env.AXIOS_DEBUG === 'true';
const isCacheEnabled = process.env.AXIOS_CACHE === 'true';

const getRequestMetadata = (config: InternalAxiosRequestConfig) => {
  const method = config.method?.toUpperCase();
  const url = config.url;
  const params = config.params;
  const headers = config.headers;

  return { method, url, params, headers };
};

const getResponseMetadata = (response?: AxiosResponse) => {
  const timestamp = response?.config?.headers?.get('x-request-timestamp');
  const method = response?.config?.method?.toUpperCase();
  const status = response?.status;
  const url = response?.config?.url;
  const params = response?.config?.params;
  const duration = timestamp && `${Date.now() - Number(timestamp)} ms`;
  const message = response?.data?.message;

  return { method, status, url, params, duration, message };
};

const onRequestFulfilled = async (config: InternalAxiosRequestConfig) => {
  if (config.skipInterceptors?.request?.fulfilled) {
    return config;
  }

  const { method, url, params, headers } = getRequestMetadata(config);

  // To get a quick metric of how many requests we send to Labrador we can filter on [Labrador Request] in logs
  // Outside of debug logic to minimize amount of text logged b/c of cost
  // Maybe temporary
  const labradorUrls = [getLabradorApiUrl(), getLabradorBackendUrl()];
  const isLabradorUrl = labradorUrls.some((labradorUrl) => url?.includes(labradorUrl));
  if (isLabradorUrl) console.log('[Labrador Request]', url);

  if (!isDebugEnabled) return config;

  logger.debug(...[method, url, params, headers].filter(Boolean));

  config.headers.set('x-request-timestamp', Date.now().toString());

  return config;
};

const onRequestRejected = (error: any) => {
  if (error.config?.skipInterceptors?.request?.rejected) {
    return Promise.reject(error);
  }

  if (!isDebugEnabled) return null;

  logger.error(error);

  return null;
};

const onResponseFulfilled = (response: AxiosResponse) => {
  if (response.config?.skipInterceptors?.response?.fulfilled) {
    return response;
  }

  if (!isDebugEnabled) return response;

  const { method, status, url, params, duration } = getResponseMetadata(response);

  // @ts-expect-error
  const cached = isCacheEnabled ? (response.cached ? 'HIT' : 'MISS') : null;

  logger.debug(...[method, status, cached, duration, url, params].filter(Boolean));

  return response;
};

const onResponseRejected = async (error: any) => {
  if (error.config?.skipInterceptors?.response?.rejected) {
    return Promise.reject(error);
  }

  if (!isDebugEnabled) return null;

  const { method, status, url, params, duration, message } = getResponseMetadata(error.response ?? error);

  logger.error(...[method, status, duration, url, params, message ?? error].filter(Boolean));

  return null;
};

const axios = Axios.create({ timeout });

axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);

if (isCacheEnabled) {
  setupCache(axios);
}

axiosRetry(axios, {
  retries: 0,
});

export const http = axios;
