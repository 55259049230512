import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent } from '@/types/component';

export type StandaloneOptionalLinkProps = LinkProps;

/**
 * @deprecated Use `Link` with `cover: true` instead
 */
export const StandaloneOptionalLink: StandaloneComponent<StandaloneOptionalLinkProps> = ({
  content,
  href,
  ...props
}) => {
  return href ? <Link {...{ content, href }} {...props} /> : content;
};
