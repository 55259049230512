import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { useAppState } from 'lib/hooks/useAppState';
import { Strossle } from './Strossle';

export interface StandaloneStrossleProps extends StandaloneComponentProps<typeof Strossle> {}

export const StandaloneStrossle: StandaloneComponent<StandaloneStrossleProps> = (props) => {
  const [{ affiliate }] = useAppState();
  const { enabled, id } = getOcelotConfig('features.strossle');

  if (!enabled || !id) return null;

  return <Strossle data-affiliate={affiliate} {...props} />;
};
