import { ButtonProps } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import Tracking from 'base/components/TrackingComponent';
import { ComponentProps, ReactNode, useState } from 'react';
import { Navigation, NavigationProps } from './Navigation';
import { NavigationBarProps } from './Navigation.Bar';
import { NavigationBarGroupProps } from './Navigation.Bar.Group';
import { NavigationDrawerProps } from './Navigation.Drawer';
import { NavigationDrawerToggleProps } from './Navigation.Drawer.Toggle';
import { NavigationLogoProps } from './Navigation.Logo';
import { NavigationMenuProps } from './Navigation.Menu';
import { NavigationMenuGroupProps } from './Navigation.Menu.Group';
import { NavigationSearchProps } from './Navigation.Search';
import { NavigationSearchToggleProps } from './Navigation.Search.Toggle';
import { NavigationSoMeProps } from './Navigation.SoMe';
import { NavigationSoMeGroupProps } from './Navigation.SoMe.Group';
import { NavigationSoMeHeadlineProps } from './Navigation.SoMe.Headline';
import { NavigationTextFieldProps } from './Navigation.TextField';

const defaults = {
  controls: (
    <>
      <Navigation.Link
        href="/prenumera"
        options={{ size: 'controls' }}
        content={
          <Navigation.Button
            content={
              <>
                <Icon name="newspaper" options={{ className: 'xs:hidden' }} />
                <span className="hidden xs:inline">Prenumerera</span>
              </>
            }
            options={{ colors: 'secondary' }}
          />
        }
      />
      <Navigation.Link
        href="/loggain"
        options={{ size: 'controls' }}
        content={
          <Navigation.Button
            content={
              <>
                <Icon name="user" options={{ className: 'sm:hidden' }} />
                <span className="hidden sm:inline">Logga in</span>
              </>
            }
          />
        }
      />
    </>
  ),
  search: (textFieldProps: NavigationTextFieldProps, submitProps?: ComponentProps<typeof Navigation.Button>) => (
    <>
      <Navigation.TextField name="s" placeholder="Sök..." {...textFieldProps} />
      <Navigation.Button content="Sök" {...mergeProps({ options: { type: 'submit' } }, submitProps)} />
    </>
  ),
};

export interface StandaloneNavigationProps extends StandaloneComponentProps {
  controls?: ReactNode;
  drawer?: {
    menu?: ReactNode;
    toggle?: ReactNode;
  };
  logo?: ReactNode;
  menu?: ReactNode;
  search?: {
    bar?: ReactNode;
    drawer?: ReactNode;
    toggle?: ReactNode;
  };
  soMe?: ReactNode;
  options?: NavigationProps & {
    $bar?: NavigationBarProps & {
      $group?: NavigationBarGroupProps;
    };
    $drawer?: NavigationDrawerProps & {
      $toggle?: NavigationDrawerToggleProps;
    };
    $link?: ComponentProps<typeof Navigation.Link>['options'];
    $logo?: NavigationLogoProps;
    $menu?: NavigationMenuProps & {
      $group: NavigationMenuGroupProps;
    };
    $search?: NavigationSearchProps & {
      $toggle?: NavigationSearchToggleProps;
      $submit?: ButtonProps;
      $textField?: NavigationTextFieldProps;
    };
    $soMe?: NavigationSoMeProps & {
      $group?: NavigationSoMeGroupProps;
      $headline?: NavigationSoMeHeadlineProps;
    };
  };
}

export const StandaloneNavigation: StandaloneComponent<StandaloneNavigationProps> = ({
  controls,
  drawer,
  menu,
  logo,
  search,
  soMe,
  options,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('');

  const { $bar, $drawer, $logo: logoProps, $menu, $search, ...baseProps } = options ?? {};

  const { $group: barGroupProps, ...barProps } = $bar ?? {};
  const { $toggle: drawerToggleProps, ...drawerProps } = $drawer ?? {};
  const { $group: menuGroupProps, ...menuProps } = $menu ?? {};
  const {
    $toggle: searchToggleProps,
    $submit: searchSubmitProps,
    $textField: textFieldProps,
    ...searchProps
  } = $search ?? {};

  return (
    <Tracking>
      <Navigation id="menu" {...baseProps} {...props}>
        <Navigation.Bar {...barProps}>
          <Navigation.Drawer.Toggle {...drawerToggleProps}>{drawer?.toggle}</Navigation.Drawer.Toggle>

          <Navigation.Logo {...logoProps} content={logo} />

          <Navigation.Bar.Group {...barGroupProps}>
            <Navigation.Menu colors="primary" {...menuProps}>
              {menu}
            </Navigation.Menu>
            <Navigation.Search {...searchProps}>
              {search?.bar ??
                defaults.search(
                  {
                    options: {
                      $input: {
                        value: searchValue,
                        onChange: ({ target }) => setSearchValue(target.value),
                        ...textFieldProps,
                      },
                    },
                  },
                  searchSubmitProps,
                )}
            </Navigation.Search>
          </Navigation.Bar.Group>

          <Navigation.Menu size="controls" variant="controls" {...menuProps}>
            {controls ?? defaults.controls}
          </Navigation.Menu>
        </Navigation.Bar>
        <Navigation.Drawer {...drawerProps}>
          <Navigation.Search size="drawer" variant="drawer" {...searchProps}>
            {search?.drawer ??
              defaults.search(
                {
                  options: {
                    $input: {
                      value: searchValue,
                      onChange: ({ target }) => setSearchValue(target.value),
                      ...textFieldProps,
                    },
                  },
                },
                searchSubmitProps,
              )}
          </Navigation.Search>
          <Navigation.Menu.Group>
            {drawer?.menu}
            {soMe}
          </Navigation.Menu.Group>
        </Navigation.Drawer>
      </Navigation>
    </Tracking>
  );
};
