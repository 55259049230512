import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';
import BaseSavedArticlesTheme from 'base/components/SavedArticles/theme';

const Button = ButtonTheme;

const Icon = tw.theme({
  base: `text-gray-300 hover:text-gray-500`,
  extend: BaseSavedArticlesTheme.Icon,
});

const Image = tw.theme({
  extend: BaseSavedArticlesTheme.Image,
});

const Tag = tw.theme({
  extend: BaseSavedArticlesTheme.Tag,
  variants: {
    size: { text: `text-category-md` },
    colors: { text: `text-logga-700` },
    variant: { text: `uppercase` },
  },
});

const SavedArticles = tw.theme({
  extend: BaseSavedArticlesTheme,
  slots: {
    headline: `text-headline-sm after:hidden md:text-headline-sm`,
    empty: `text-body-lg`,
    articleCaption: `pt-1.5 text-headline-xs md:text-headline-sm`,
    articleDescription: `pt-1.5 text-body-sm md:pt-2 md:text-body-md`,
  },
});

export default Object.assign(SavedArticles, { Button, Icon, Image, Tag });
