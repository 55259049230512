import { tw } from '@/utils/tw';

const Image = tw.theme({
  slots: {
    base: 'not-prose relative block w-full object-cover data-[fill]:aspect-default',
    group: 'relative flex w-full flex-col gap-y-2',
    captionGroup: 'block text-body-md',
    caption: '',
    byline: 'whitespace-nowrap text-gray-700',
  },
});

export default Image;
