import { Button as StandaloneButton } from '@/components/Button';
import { Checkbox as CheckboxStandaloneField } from '@/components/Checkbox';
import { ContactEditorialTheme } from '@/components/ContactEditorial/theme';
import { Dialog as StandaloneDialog } from '@/components/Dialog';
import { TextArea as StandaloneTextArea } from '@/components/TextArea';
import { TextField as StandaloneTextField } from '@/components/TextField';
import { tw } from '@/utils/tw';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ContactEditorialTheme });

const Base = $({ as: 'form', slot: 'base' });
const Checkbox = GenericSlot({ as: CheckboxStandaloneField, theme: ContactEditorialTheme.Checkbox });
const Headline = $({ as: 'h3', slot: 'headline' });
const Caption = $({ as: 'p', slot: 'caption' });
const SubmitButton = GenericSlot({ as: StandaloneButton, theme: tw.theme({}) });
const TextArea = GenericSlot({ as: StandaloneTextArea, theme: ContactEditorialTheme.TextArea });
const TextField = GenericSlot({ as: StandaloneTextField, theme: ContactEditorialTheme.TextField });
const Dialog = GenericSlot({ as: StandaloneDialog, theme: ContactEditorialTheme.Dialog });

export const ContactEditorial = Object.assign(Base, {
  Checkbox,
  Dialog,
  Headline,
  Caption,
  SubmitButton,
  TextArea,
  TextField,
});
