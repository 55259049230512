import { getSecondsDiff } from '@/utils/getSecondsDiff';
import { getOcelotConfig } from 'lib/configs/ocelot';

export const getSiteId = () => {
  const siteId: string = process.env.OCELOT_LABRADOR_SITE_ID || '';
  if (!siteId) throw new Error('Missing env: OCELOT_LABRADOR_SITE_ID');
  return siteId;
};

export const getEnvironment = () => {
  const environment: string = process.env.OCELOT_ENVIRONMENT || '';
  if (!environment) throw new Error('Missing env: OCELOT_ENVIRONMENT');
  return environment;
};

export const columnsToPercentageWidth = (columns: number) => {
  return parseFloat(((100 / 12) * columns).toFixed(3));
};

export const truncToDecimal = (n: number, d: number) => Math.trunc(n * Math.pow(10, d)) / Math.pow(10, d);

export const isValidWidth = (width: number) => {
  const validWidths = [8.3, 16.6, 25.0, 33.3, 41.6, 50.0, 58.3, 66.6, 75.0, 83.3, 91.6, 100.0];

  return validWidths.includes(truncToDecimal(width, 1));
};

export const maybeConvertColumnsToPercentageWidth = (width: number) => {
  if (isValidWidth(width)) return width;

  const newWidth = columnsToPercentageWidth(width);
  if (isValidWidth(newWidth)) return newWidth;

  return 100;
};

export const isProduction = getEnvironment() === 'prod';

const tagsToCacheTags = (tags: string[]): string[] => {
  return tags.map((i) => `tag-${i?.toLowerCase()?.replace(/[^a-zA-Z0-9\,]+/g, '-')}`.replace(/-{2,}/g, '-'));
};

export const getCacheTags = (type: string, id: number, tags?: string[]): string => {
  if (type === 'article') {
    return `article,${id}`;
  }
  return tagsToCacheTags(tags || [])
    .join(',')
    .concat(`,front`, `,${id}`);
};

export const getCacheChannels = (type: string, id: number, tags?: string[]): string => {
  if (type === 'front') {
    return `${id}`;
  }
  return tagsToCacheTags(tags || [])
    .join(',')
    .concat(`,${id}`);
};

export const wait = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getVersionNo = (filename: string = ''): string =>
  filename.match(/([\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}([a-z]+[\d]{0,3})?)/i)?.[0] || '';

export const isValidPage = (type: string) => type != '404' && type != '500';
export const stripSlashes = (str?: string): string => str?.trim()?.replace(/^\/+|\/+$/g, '') || '';
export const stripLeadingSlashes = (str: string): string => str?.trim()?.replace(/^\/+/g, '');
export const isExpired = (cacheTs: number, refreshTtl: number) => getSecondsDiff(cacheTs, Date.now()) > refreshTtl;
export const isOriginDown = () => process.env.OCELOT_ORIGIN_STATUS === 'down';

export const slugifySection = (section: string): string => {
  if (typeof section !== 'string') return '';

  const slugged = section
    ?.toLowerCase()
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^a-zA-Z0-9\-\s!?/]+/g, '')
    .replace(/[?!]/g, '')
    .replace(/\s/g, '-');

  return slugged;
};

export const redirectRoute = (route: string[]) => {
  const redirects = getOcelotConfig('common.redirects');

  const { to: redirectTo } = redirects.find(({ from }) => new RegExp(from).test(route.join('/'))) || {};
  return redirectTo ? redirectTo.split('/') : undefined;
};
