import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/standalone/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  base: ['focus:ring-0'],
  variants: {
    colors: {
      primary: [
        'text-white',
        'border-logga-600',
        'bg-logga-600',
        'hover:bg-logga-700',
        'hover:border-logga-700',
        'focus:bg-logga-600',
        'focus:border-logga-600',
        'active:bg-logga-800',
        'active:border-logga-800',
      ],
      secondary: [
        'bg-white',
        'border-logga-600',
        'text-logga-600',
        'hover:bg-logga-300',
        'focus:bg-white',
        'active:bg-logga-400',
      ],
    },
    size: {
      small: ['px-3', 'gap-1', 'h-7.5', 'text-button-sm'],
      medium: ['px-4', 'gap-1.5', 'h-9', 'text-button-md'],
      large: ['px-6', 'gap-1.5', 'h-11.5', 'text-button-md'],
    },
  },
});

export default Object.assign(Button, { Icon });
