import { isGoogleAnalyticsLoaded } from 'base/components/RecommendedArticles/helpers';
import { isArrayNotEmpty } from 'typesafe-utils';
import { logger } from './logger';

export const getGoogleAnalyticsId = () => {
  if (!isGoogleAnalyticsLoaded()) {
    logger.error('window.ga or window.ga.getAll is not a function (i.e. GA not available/setup).');
    return null;
  }

  const all = window.ga.getAll();
  const gaId = Array.isArray(all) && isArrayNotEmpty(all) ? all[0].get('clientId') : null;

  if (gaId === null) {
    logger.error('Could not determine gaId for the current visitor.');
    return null;
  }

  if (!/^\d+\.\d+$/.test(gaId)) {
    logger.error('The gaId found did not match the expected format.');
    return null;
  }

  return gaId;
};
