import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { ScrollArticleLabel } from './ScrollArticleLabel';

export interface StandaloneScrollArticleLabelProps extends StandaloneComponentProps<typeof ScrollArticleLabel> {
  content?: ReactNode;
}

export const StandaloneScrollArticleLabel: StandaloneComponent<StandaloneScrollArticleLabelProps> = ({
  content,
  ...props
}) => {
  if (!content) {
    return null;
  }

  return <ScrollArticleLabel {...props}>{content}</ScrollArticleLabel>;
};
