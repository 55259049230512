import type { FunctionComponent } from 'react';
import type { TypeGuard } from 'typesafe-utils';

import { BsArrowDown } from 'react-icons/bs';
import {
  FaFacebook,
  FaFacebookSquare,
  FaInstagram,
  FaInstagramSquare,
  FaPinterest,
  FaPinterestSquare,
  FaRegClock,
  FaRegUser,
  FaYoutube,
  FaYoutubeSquare,
} from 'react-icons/fa';
import { FaCircleCheck, FaSquareXTwitter, FaXTwitter } from 'react-icons/fa6';
import { LuDumbbell } from 'react-icons/lu';
import {
  MdAdd,
  MdArrowForward,
  MdCancel,
  MdCheck,
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdContentCopy,
  MdDone,
  MdError,
  MdMenu,
  MdNewspaper,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineFileDownload,
  MdOutlineMailOutline,
  MdOutlineOpenInNew,
  MdOutlineRestaurant,
  MdPlayCircle,
  MdRemove,
  MdRssFeed,
  MdSearch,
  MdSearchOff,
} from 'react-icons/md';
import { PiChatsBold } from 'react-icons/pi';

import ArrowFilled from './svg/arrow-filled.svg';
import SliderForwardArrow from './svg/arrow-forward-slider.svg';
import Bin from './svg/bin.svg';
import Cookie from './svg/cookie.svg';
import ElleAccordion from './svg/elle-accordion.svg';
import Facebook from './svg/facebook.svg';
import FilledArrow from './svg/filledArrow.svg';
import MailHeart from './svg/mailHeart.svg';
import Pinterest from './svg/pinterest.svg';
import PlayIcon from './svg/play.svg';
import Sent from './svg/sent.svg';
import SliderArrow from './svg/sliderArrow.svg';
import TeaserArrow from './svg/teaser-arrow.svg';
import Tiktok from './svg/tiktok.svg';

export const iconsDictionary: Record<string, FunctionComponent> = {
  add: MdAdd,
  arrowForward: MdArrowForward,
  arrowFilled: ArrowFilled,
  bin: Bin,
  cancel: MdCancel,
  chats: PiChatsBold,
  chevronLeft: MdChevronLeft,
  chevronRight: MdChevronRight,
  close: MdClose,
  copy: MdContentCopy,
  done: MdDone,
  download: MdOutlineFileDownload,
  elleAccordion: ElleAccordion,
  envelope: MdOutlineMailOutline,
  error: MdError,
  externalLink: MdOutlineOpenInNew,
  facebook: Facebook,
  filledArrow: FilledArrow,
  facebookRound: FaFacebook,
  facebookSquare: FaFacebookSquare,
  instagram: FaInstagram,
  instagramSquare: FaInstagramSquare,
  menu: MdMenu,
  newspaper: MdNewspaper,
  pinterest: Pinterest,
  pinterestRound: FaPinterest,
  pinterestSquare: FaPinterestSquare,
  play: PlayIcon,
  playCircle: MdPlayCircle,
  remove: MdRemove,
  rssFeed: MdRssFeed,
  search: MdSearch,
  searchOff: MdSearchOff,
  sent: Sent,
  showLess: MdOutlineExpandLess,
  showMore: MdOutlineExpandMore,
  sliderArrow: SliderArrow,
  sliderForwardArrow: SliderForwardArrow,
  tiktok: Tiktok,
  x: FaXTwitter,
  xSquare: FaSquareXTwitter,
  user: FaRegUser,
  youtube: FaYoutube,
  youtubeSquare: FaYoutubeSquare,
  teaserArrow: TeaserArrow,
  cookie: Cookie,
  clock: FaRegClock,
  toolsKitchen: MdOutlineRestaurant,
  gym: LuDumbbell,
  mailHeart: MailHeart,
  circleCheck: FaCircleCheck,
  arrowDown: BsArrowDown,
  check: MdCheck,
};

export function isIconKey<T>(key: T): key is TypeGuard<keyof typeof iconsDictionary, T> {
  return Boolean(iconsDictionary[key as keyof typeof iconsDictionary]);
}
