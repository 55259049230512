import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode } from 'react';
import { AuthorDetails } from './AuthorDetails';

export interface StandaloneAuthorDetailsProps extends StandaloneComponentProps<typeof AuthorDetails> {
  caption?: ReactNode;
  email?: ReactNode;
  description?: ReactNode;
  image?: ComponentProps<typeof AuthorDetails.Image>;
  soMeList?: ComponentProps<typeof AuthorDetails.SoMe>[];
  title?: ReactNode;
}

export const StandaloneAuthorDetails: StandaloneComponent<StandaloneAuthorDetailsProps> = ({
  caption,
  email,
  description,
  image,
  soMeList,
  title,
  ...props
}) => {
  return (
    <AuthorDetails {...props}>
      {image && <AuthorDetails.Image {...mergeProps({ options: props.options?.$image }, image)} />}
      {title && <AuthorDetails.Headline>{title}</AuthorDetails.Headline>}
      {caption && <AuthorDetails.Caption>{caption}</AuthorDetails.Caption>}
      {email && <AuthorDetails.Email>{email}</AuthorDetails.Email>}
      {soMeList && soMeList.length > 0 && (
        <AuthorDetails.SoMeGroup>
          {soMeList.map((soMe, index) => (
            <AuthorDetails.SoMe key={index} {...mergeProps({ options: props.options?.$soMe }, soMe)} />
          ))}
        </AuthorDetails.SoMeGroup>
      )}
      {description && <AuthorDetails.Description>{description}</AuthorDetails.Description>}
    </AuthorDetails>
  );
};
