import { SchemaMarkupProps } from '@/components/SchemaMarkup';
import { Content } from '@/types/content';
import { getDomain } from '@/utils/getDomain';
import { getImageServer } from '@/utils/getImageServer';
import { getUrl } from '@/utils/getUrl';
import { logger } from '@/utils/logger';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { NewsMediaOrganization, WebPage } from 'schema-dts';
import { getArticlePageSchema } from './articlePageSchema';
import { getCollectionPageSchema } from './collectionPageSchema';
import { getVideoPageSchema, getVideoWebPageSchema } from './videoSchema';
import { getWebPageSchema } from './webPageSchema';

export interface CustomSchemaMarkupConfig {
  verificationFactCheckingPolicy: string;
  correctionsPolicy: string;
  unnamedSourcesPolicy: string;
  missionCoveragePrioritesPolicy: string;
}

const schemaMarkupConfig = getOcelotConfig('seo.schemaMarkup');

export const getPublishedUrl = (publishedUrl: string) =>
  getDomain(false) + (publishedUrl === '/index' ? '/' : publishedUrl);

export const publisher: NewsMediaOrganization = {
  '@type': 'NewsMediaOrganization',
  name: schemaMarkupConfig.name || '',
  foundingDate: schemaMarkupConfig.foundingDate ?? '',
  legalName: 'Aller Media AB',
  alternateName: schemaMarkupConfig.alternateName ?? [],
  logo: { '@type': 'ImageObject', url: `${getDomain(false)}/common/image/aller-media-logo.png` },
  contactPoint: { '@type': 'ContactPoint', telephone: '+46857801000', contactType: 'customer service' },
};

export const verificationFactCheckingPolicy: string | undefined = schemaMarkupConfig.verificationFactCheckingPolicy;

export const correctionsPolicy: string | undefined = schemaMarkupConfig.correctionsPolicy;

export const unnamedSourcesPolicy: string | undefined = schemaMarkupConfig.unnamedSourcesPolicy;

export const missionCoveragePrioritesPolicy: string | undefined = schemaMarkupConfig.missionCoveragePrioritesPolicy;

export const sameAs: WebPage['sameAs'] | undefined = schemaMarkupConfig.sameAs;

export const publishingPrinciples: WebPage['publishingPrinciples'] | undefined =
  schemaMarkupConfig.publishingPrinciples;

export const getImageSrc = (imageId: string, width: number, height: number): string | undefined => {
  const url = getUrl(`image-${imageId}`, getImageServer());
  if (!url) return undefined;

  const params = new URLSearchParams({
    imageId,
    width: width.toString(),
    height: height.toString(),
  });

  url.search = params.toString();

  return url.href;
};

export const getBylineUrl = (byline?: string) => {
  return `${getDomain(false)}/vara-skribenter/${(byline ?? '')
    ?.toLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.split(' ')
    ?.join('_')}`;
};

export const getSchema = (pageData?: Content, options?: SchemaMarkupProps['options']) => {
  if (!pageData) {
    logger.warn('SEO schema markup not generated!');
    return {};
  }

  const webPageSchemaPages = getOcelotConfig('seo.schemaMarkup.webPages') || [];
  const isVideoWebPage = pageData.type === 'videoWebPage';
  const isVideoPage = pageData.type === 'videoPage';
  const isArticle = pageData.type === 'article';
  const publishedUrl = pageData.data?.publishedUrl || '';
  const isWebPage = webPageSchemaPages?.some((page: string) => publishedUrl.includes(page as string));

  if (isVideoWebPage) {
    return getVideoWebPageSchema(pageData.data);
  }
  if (isVideoPage) {
    return getVideoPageSchema(pageData.data);
  }

  if (isArticle) {
    return getArticlePageSchema(pageData, options?.$articlePageSchema);
  }

  if (isWebPage) {
    return getWebPageSchema(pageData, options?.$webPageSchema);
  }

  return getCollectionPageSchema(pageData, options?.$collectionPageSchema);
};
