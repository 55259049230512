import { Button as StandaloneButton } from '@/components/Button';
import { CheckoutTheme } from '@/components/Checkout/theme';
import { KlarnaPaymentWith } from '@/components/KlarnaPayment';
import { useClassName, useTheme } from '@/styles/Checkout';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { GenericSlot } from 'base/components/GenericSlot';
import { CheckoutFormComponent } from './Checkout.Form';
import { CheckoutFormCheckboxComponent } from './Checkout.Form.Checkbox';
import { CheckoutFormTextFieldComponent } from './Checkout.Form.TextField';

const GenericComponent = GenericComponentFactory({ useClassName });

const Section = GenericComponent({ as: 'section', styles: { key: '' } });
const Errors = GenericComponent({ as: 'div', styles: { key: 'form_errors' } });
const ErrorsMessage = GenericComponent({ as: 'span', styles: { key: 'form_errors_message' } });
const Closed = GenericComponent({ as: 'div', styles: { key: 'closed' } });
const Policy = GenericComponent({ as: 'p', styles: { key: 'form_policy' } });
const Payment = KlarnaPaymentWith({ theme: { useTheme } });
const Button = GenericSlot({ as: StandaloneButton, theme: CheckoutTheme.Button });

export const Checkout = Object.assign(Section, {
  Form: Object.assign(CheckoutFormComponent, {
    TextField: CheckoutFormTextFieldComponent,
    Checkbox: CheckoutFormCheckboxComponent,
    Payment,
    Button,
    Errors: Object.assign(Errors, { Message: ErrorsMessage }),
    Policy,
  }),
  Closed,
});
