import { AdTemplate } from '@/components/AdTemplate';
import { Base } from '@/components/Base';
import { Consent } from '@/components/Consent';
import { PageMeta } from '@/components/PageMeta';
import { SchemaMarkup } from '@/components/SchemaMarkup';
import { Tracking } from '@/components/Tracking';
import { Meta } from '@/types/content';
import { isDeviceInvalid } from '@/utils/isDeviceInvalid';
import { CLOUDFLARE_TTL } from '@/utils/ttl';
import { withCacheControlHeaders } from '@/utils/withCacheControlHeaders';
import { withLockedContentHeaders } from '@/utils/withLockedContentHeaders';
import { withOcelotCacheTagsHeaders } from '@/utils/withOcelotCacheTagsHeaders';
import { withSerializationGuard } from '@/utils/withSerializationGuard';
import { withTestContentHeaders } from '@/utils/withTestContentHeaders';
import { InfinityScrollFront } from 'base/components/InfinityScrollFront';
import EmbedLogin from 'lib/AlltIdAuth/index';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { getLabradorPage } from 'lib/labrador/getLabradorPage';
import { getLabradorPageProps, LabradorPageProps } from 'lib/labrador/getLabradorPageProps';
import { handleCommonLabradorPageStatus } from 'lib/labrador/handleCommonLabradorPageStatus';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { GetServerSideProps, NextPage } from 'next';

const Page: NextPage<LabradorPageProps> = ({ pageData, adsData, analyticsData }) => {
  const isInfinityScrollFrontEnabled = getOcelotConfig('features.infinityScrollFront.enabled');
  const infinityScrollId = pageData.meta.infinityScrollID;

  return (
    <Base
      content={
        <>
          <Consent />
          <PageMeta pageData={pageData} />
          <AdTemplate pageMeta={pageData?.meta as Meta} adsData={adsData} />
          <Tracking {...analyticsData} />
          <SchemaMarkup pageData={pageData} />
          <EmbedLogin />
          {renderPostTypeComponent(pageData)}
          {isInfinityScrollFrontEnabled && infinityScrollId && (
            <InfinityScrollFront pageData={pageData} sourceRoute={['_scroll', 'front', infinityScrollId].join('/')} />
          )}
          <div id="modal" />
        </>
      }
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { device, params = ['index'] } = context.params as { device: string; tag: string; params?: string[] };

  if (isDeviceInvalid(device)) {
    return { notFound: true };
  }

  const routeKey = params;
  const data = await getLabradorPage(device, routeKey, context.req.url);

  const commonLabradorResponse = handleCommonLabradorPageStatus(data);
  if (commonLabradorResponse) return commonLabradorResponse;

  withOcelotCacheTagsHeaders(context, data);
  withLockedContentHeaders(context, data);
  withTestContentHeaders(context, data);
  withCacheControlHeaders(context.res, CLOUDFLARE_TTL);

  return withSerializationGuard({
    props: getLabradorPageProps(data),
  });
};

export default Page;
