import { ArticleHeaderTheme } from '@/components/ArticleHeader/theme';
import { Link as StandaloneLink } from '@/components/Link';
import { Tag as StandaloneTag } from '@/components/Tag';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticleHeaderTheme });

const Base = $({ as: 'header', slot: 'base' });
const Bylines = $({ as: 'div', slot: 'bylines' });
const Category = $({ as: 'div', slot: 'category' });
const Date = $({ as: 'div', slot: 'date' });
const Description = $({ as: 'div', slot: 'description' });
const Headline = $({ as: 'h1', slot: 'headline' });
const Media = $({ as: 'div', slot: 'media' });
const Tags = $({ as: 'div', slot: 'tags' });
const Tag = $({ as: StandaloneTag, theme: ArticleHeaderTheme.Tag });
const ContactUsLink = GenericSlot({ as: StandaloneLink, theme: ArticleHeaderTheme.ContactUsLink });

export const ArticleHeader = Object.assign(Base, {
  Bylines,
  ContactUsLink,
  Date,
  Category,
  Description,
  Headline,
  Media,
  Tags,
  Tag,
});
