import { KilkayaMetadata } from 'base/components/Tracking/types';
import { FC, ReactNode, useState } from 'react';
import { useEffectOnce } from 'react-use';

interface KilkayaProps {
  k5aMeta: KilkayaMetadata | undefined;
  children?: ReactNode;
}

/**
 * Renders a script tag that injects the kilkaya metadata into `window.k5aMeta`.
 *
 * @param {KilkayaProps} props - The kilkaya metadata to add.
 * @returns {JSX.Element} The script tag embedding the metadata into the page.
 */
export const Kilkaya: FC<KilkayaProps> = ({ k5aMeta, children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffectOnce(() => {
    window.k5aMeta = { ...(window.k5aMeta || {}), ...(k5aMeta || {}) };
    setIsReady(true);
  });

  return <>{isReady && children}</>;
};
