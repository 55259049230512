import { Button as StandaloneButton } from '@/components/Button';
import { ContentLockTheme } from '@/components/ContentLock/theme';
import { useClassName } from '@/styles/ContentLock';
import { PropsFromComponent, PropsFromSubcomponents } from '@/types/component';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { GenericSlot } from 'base/components/GenericSlot';

const Button = GenericSlot({ as: StandaloneButton, theme: ContentLockTheme.Button });

export type ContentLockProps = PropsFromComponent<typeof ContentLock>;
export type ContentLockSubcomponentProps = PropsFromSubcomponents<typeof ContentLock>;

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'section' });
const Login = GenericComponent({ styles: { key: 'login' } });
const LoginCaption = GenericComponent({ as: 'p', styles: { key: 'login_caption' } });
const LoginFooter = GenericComponent({ as: 'p', styles: { key: 'login_footer' } });
const LoginHeadline = GenericComponent({ as: 'h3', styles: { key: 'login_headline' } });
const Purchase = GenericComponent({ styles: { key: 'purchase' } });

export const ContentLock = Object.assign(Base, {
  Login: Object.assign(Login, {
    Button,
    Caption: LoginCaption,
    Footer: LoginFooter,
    Headline: LoginHeadline,
  }),
  Purchase,
});
