import { ArticleDate, ArticleDateProps } from '@/components/ArticleDate';
import { Byline, BylineProps } from '@/components/Byline';
import { Image, ImageProps } from '@/components/Image';
import { JwVideoArticle, JwVideoArticleProps } from '@/components/JwVideo/Article';
import { LinkProps } from '@/components/Link';
import { SaveArticleButton } from '@/components/SaveArticleButton';
import { TagProps } from '@/components/Tag';
import { useStableProps } from '@/hooks/useStable';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { ReactNode, useMemo } from 'react';
import { ArticleHeader } from './ArticleHeader';

export interface StandaloneArticleHeaderProps extends StandaloneComponentProps<typeof ArticleHeader> {
  bylines?: BylineProps[];
  category?: {
    value?: ReactNode;
    url?: string;
  };
  video?: JwVideoArticleProps;
  description?: ReactNode;
  headline?: ReactNode;
  image?: ImageProps;
  tags?: TagProps[];
  datetime?: ArticleDateProps;
  withSaveArticle?: boolean;
  contactUsLink?: LinkProps;
}

export const StandaloneArticleHeader: StandaloneComponent<StandaloneArticleHeaderProps> = ({
  bylines,
  category,
  contactUsLink,
  datetime,
  description,
  headline,
  image,
  tags,
  video,
  withSaveArticle,
  ...props
}) => {
  const memoTags = useMemo(
    () =>
      tags?.map((tag, index) => (
        <ArticleHeader.Tag
          key={index}
          link={{ href: `/etikett/${tag.value}` }}
          {...mergeProps(
            {
              options: {
                variant: 'text',
                size: 'text',
                colors: 'text',
              },
            } as const,
            tag,
          )}
        />
      )),
    [tags],
  );

  const memoCategoryTag = useMemo(
    () => (
      <ArticleHeader.Tag
        value={category?.value}
        link={{ href: category?.url || `etikett/${category?.value}` }}
        options={{
          variant: 'text',
          size: 'text',
          colors: 'text',
        }}
      />
    ),
    [category?.url, category?.value],
  );

  const stableImageProps = useStableProps({ fill: true, priority: true }, image);
  const imageComponent = image?.src && <Image {...stableImageProps} />;

  const videoComponent = video && (
    <JwVideoArticle
      {...mergeProps(video, {
        className: 'border-y-0 !mb-0',
        disableLazyLoad: true,
      })}
    />
  );

  const media = imageComponent || videoComponent || null;

  return (
    <ArticleHeader {...props}>
      {Boolean(memoTags?.length) && <ArticleHeader.Tags>{memoTags}</ArticleHeader.Tags>}
      {headline && <ArticleHeader.Headline>{headline}</ArticleHeader.Headline>}
      {category?.value && <ArticleHeader.Category>{memoCategoryTag}</ArticleHeader.Category>}
      {datetime?.publishedDatetime && (
        <ArticleHeader.Date>
          <ArticleDate {...datetime} />
        </ArticleHeader.Date>
      )}
      {(Boolean(bylines?.length) || contactUsLink) && (
        <ArticleHeader.Bylines>
          {bylines?.map((byline, index) => (
            <Byline
              {...mergeProps(
                {
                  options: {
                    $image: {
                      priority: true,
                    },
                  },
                } as const,
                byline,
              )}
              key={index}
            />
          ))}
          {contactUsLink && (
            <ArticleHeader.ContactUsLink
              {...contactUsLink}
              options={mergeOptions(props.options?.$contactUsLink, contactUsLink.options)}
            />
          )}
        </ArticleHeader.Bylines>
      )}
      {media && <ArticleHeader.Media>{media}</ArticleHeader.Media>}
      {description && <ArticleHeader.Description>{description}</ArticleHeader.Description>}
      {withSaveArticle && <SaveArticleButton />}
    </ArticleHeader>
  );
};
