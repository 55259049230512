import { getSite } from '@/utils/getSite';
import { DefaultRequestOptions } from './types';

export const brands = {
  elle: {
    defaultHidden: false,
    showMoreBelowArticle: true,
  },
  default: {
    defaultHidden: false,
    showMoreBelowArticle: false,
  },
};

const site = getSite() as keyof typeof brands;

export const defaultRequestOptions: DefaultRequestOptions = {
  // (required) Current article ID.
  articleId: null,

  // (required) Current brand (i.e. "allas").
  brand: site,

  // gaId to use. Leave as null to use default logic of finding the visitors gaId.
  gaId: null,

  // Microservice hostname. Leave as null to have this auto.
  hostname: null,

  // Time in milliseconds to return the result before considered timed out. Provide null for no timeout.
  responseTimeout: 5000,
};

export const brandConfig = brands[site] || brands.default;

export const globalOptions = {
  // CMP close wait timeout (ms).
  cmpWaitTimeout: 5000,

  // Timeout between each CMP "closed" check (ms).
  cmpWaitCheckFrequency: 100,

  // Google Analytics "loading" wait timeout (ms).
  gaWaitTimeout: 5000,

  // Timeout between each Google Analytics "loading" check (ms).
  gaWaitCheckFrequency: 100,
};

export const microserviceHostname = 'recommendations.allermedia.se' as string;

// Some values are allowed to be overridden by supplying them in the query string.
// Prefix them with 'ra_' then all lowercase.
export const qsValuesDefault: {
  gaId: string | null;
  showRecommendations: boolean | null; // Provide 0 or 1, converted to boolean.
} = {
  gaId: null,
  showRecommendations: null, // Provide 0 or 1, converted to boolean.
};

// Some values can be set using Google Optimize (document level).
// Prefix them with 'ra_' then all lowercase.
export const optimizeValuesDefault: {
  showRecommendations: boolean | null;
} = {
  showRecommendations: null, // Provide boolean.
};
