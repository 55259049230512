import { ButtonTheme } from '@/components/Button/theme';
import { IconTheme } from '@/components/Icon/theme';
import { ImageTheme } from '@/components/Image/theme';
import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Button = tw.theme({
  extend: ButtonTheme,
  base: ['block'],
  variants: {
    large: ['mt-4', 'mx-auto'],
  },
});

const Icon = tw.theme({
  extend: IconTheme,
  base: `cursor-pointer text-gray-400`,
  defaultVariants: {
    size: 'small',
  },
});

const Image = tw.theme({
  extend: ImageTheme,
});

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    variant: 'text',
    colors: 'text',
    size: 'text',
  },
});

const SavedArticles = tw.theme({
  slots: {
    base: ``,
    wrapper: `grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4`,
    headline: `mb-3 flex items-center gap-3 bg-white pr-3 text-headline-md after:h-0.25 after:flex-grow after:bg-black`,
    empty: `col-span-4 mt-3 flex flex-col gap-6`,
    article: ``,
    articleHeader: `flex items-center justify-between pt-3`,
    articleCaption: `pt-3 text-headline-sm md:text-headline-md`,
    articleDescription: `text-body-xs pt-2 md:text-body-md`,
  },
});

export default Object.assign(SavedArticles, { Button, Icon, Image, Tag });
