import { tw } from '@/utils/tw';

const VideoReelsTheme = tw.theme({
  slots: {
    base: 'mb-8 max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg',
    element: 'w-full',
    heading: 'mb-0 text-headline-md',
  },
});

export default VideoReelsTheme;
