import { tw } from '@/utils/tw';

const Link = tw.theme({
  base: [
    'text-primary-900',
    'text-headline-sm',
    'pt-6',
    'md:pt-0',
    'md:absolute',
    'md:top-6',
    'md:right-6',
    'underline',
    'block',
    'text-center',
  ],
});

export default Object.assign({}, { Link });
