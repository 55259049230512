import { ArticleInfo } from '../types/articleInfo';
import { adsSetup } from './adsSetup';

export const checkForUserConsentAndGetAdConfig = ({
  articleInfo,
  autostart,
  ypPartId,
  isNativeAd = false,
  isVerticalVideo,
  hideAds,
  hasScrollArticles,
}: {
  articleInfo?: ArticleInfo;
  autostart: string | boolean;
  ypPartId: string | null;
  isNativeAd?: boolean;
  isVerticalVideo?: boolean;
  hideAds?: boolean;
  hasScrollArticles?: boolean;
}): {} => {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.userConsent) {
      window.userConsent?.isTargetingApproved(async (response: any) => {
        if (response && !isNativeAd && !hideAds) {
          //Targetting is approved here
          const customAdConfig = await adsSetup({
            articleInfo,
            autoStart: autostart,
            wasYouplay: ypPartId !== null,
            isVerticalVideo,
            hasScrollArticles,
          });
          resolve(customAdConfig);
        } else {
          resolve({ noConsent: true });
        }
      });
    } else {
      resolve({ noConsent: true });
    }
  });
};
