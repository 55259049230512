import { Accordion } from '@/components/Accordion';
import { componentTheme } from '@/styles/Navigation';
import { PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { TrackingProps } from '@/utils/withTrackingAttributes';
import { Navigation } from '../Navigation';
import { NavigationMenuProps } from '../Navigation.Menu';

const getTrackingAttributes = (tracking: TrackingProps) => {
  const defaultTrackingAttributes = {
    category: 'menu',
    extraInfo: [{ type: 'main_navigation_menu' }],
  };

  return { ...defaultTrackingAttributes, ...tracking };
};

type NavigationLinkProps = PropsFromComponent<typeof Navigation.Link>;

type DrawerMenuLink = NavigationLinkProps | DrawerMenuLink[];
type DrawerMenuLinks = { links: DrawerMenuLink[]; depth?: number; options?: NavigationLinkProps['options'] };

const renderNavigationLink = (
  { tracking, ...link }: NavigationLinkProps,
  depth: number,
  options?: NavigationLinkProps['options'],
  index?: number,
) => {
  return (
    <Navigation.Link
      key={index}
      data-depth={depth}
      tracking={
        link.href
          ? getTrackingAttributes({
              url: link.href,
              name: link.content?.toString(),
              ...tracking,
            })
          : {}
      }
      {...mergeProps(
        {
          options: {
            colors: 'drawer',
            size: 'drawer',
            ...options,
          },
        } as const,
        link,
      )}
    />
  );
};

const getNavigationDrawerMenuLinks = ({ links, depth = 1, options }: DrawerMenuLinks) => {
  return links.map((item, index) => {
    if (Array.isArray(item)) {
      const [title, ...links] = item;

      if (Array.isArray(title)) {
        getNavigationDrawerMenuLinks({ links: title, depth: depth + 1, options });
      } else {
        const isLink = typeof title.href !== 'undefined';

        return (
          <Accordion
            key={`${depth}-${index}`}
            title={renderNavigationLink(title, depth, {
              variant: 'drawerPrimary',
              className: isLink ? 'cts-tracking-object' : '',
              ...options,
            })}
            content={getNavigationDrawerMenuLinks({
              links,
              depth: depth + 1,
              options,
            })}
            // @ts-expect-error: `Navigation` is non-slot architecture
            options={{ theme: componentTheme('accordion') }}
          />
        );
      }

      return;
    }

    return renderNavigationLink(
      item,
      depth,
      {
        variant: depth === 1 ? 'drawerPrimary' : 'drawerSecondary',
        className: 'cts-tracking-object',
        ...options,
      },
      index,
    );
  });
};

type DrawerMenuGroup = NavigationMenuProps & { links: DrawerMenuLink[] };

type DrawerMenu = {
  toggle?: React.ReactNode;
  menu: DrawerMenuGroup[];
  options?: {
    $menu?: NavigationMenuProps;
    $link?: NavigationLinkProps['options'];
  };
};

export const getNavigationDrawerMenu = ({ toggle, menu, options }: DrawerMenu) => {
  return {
    toggle: toggle ?? <Navigation.Drawer.Toggle />,
    menu: menu?.map(({ links, ...menu }, index) => (
      <Navigation.Menu key={index} size="drawer" variant="drawer" {...options?.$menu} {...menu}>
        {getNavigationDrawerMenuLinks({ links, options: options?.$link })}
      </Navigation.Menu>
    )),
  };
};
