import { ButtonWith, Button as StandaloneButton } from '@/components/Button';
import { DialogTheme } from '@/components/Dialog/theme';
import { Icon as StandaloneIcon } from '@/components/Icon';
import { useTheme } from '@/styles/Dialog';
import { tw } from '@/utils/tw';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: DialogTheme });

const Base = $({ as: 'dialog', slot: 'base' });
const CloseButton = ButtonWith({ theme: { useTheme } });
const Headline = $({ as: 'h3', slot: 'headline' });
const Content = $({ as: 'p', slot: 'content' });
const ButtonGroup = $({ as: 'div', slot: 'buttonGroup' });
const CloseIcon = GenericSlot({ as: StandaloneIcon, theme: DialogTheme.CloseIcon });
const DialogIcon = GenericSlot({ as: StandaloneIcon, theme: DialogTheme.DialogIcon });
const PrimaryActionButton = GenericSlot({ as: StandaloneButton, theme: tw.theme({}) });
const SecondaryActionButton = GenericSlot({ as: StandaloneButton, theme: tw.theme({}) });

export const Dialog = Object.assign(Base, {
  CloseButton,
  Headline,
  Content,
  CloseIcon,
  DialogIcon,
  ButtonGroup,
  PrimaryActionButton,
  SecondaryActionButton,
});
