import { ButtonProps } from '@/components/Button';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ReactNode } from 'react';
import { Row } from './Row';

export interface StandaloneRowProps extends StandaloneComponentProps<typeof Row> {
  button?: ButtonProps;
  caption?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  headline?: ReactNode;
}

export const StandaloneRow: StandaloneComponent<StandaloneRowProps> = ({
  button,
  caption,
  content,
  footer,
  headline,
  ...props
}) => {
  return (
    <Row {...props}>
      {headline && <Row.Headline>{headline}</Row.Headline>}
      {caption && <Row.Caption>{caption}</Row.Caption>}
      {content}
      {(footer || button) && (
        <Row.Footer>
          {footer}
          {button && <Row.Button {...mergeProps({ options: props.options?.$button }, button)} />}
        </Row.Footer>
      )}
    </Row>
  );
};
