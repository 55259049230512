import { CheckoutBody, CheckoutBodyProps } from '@/components/CheckoutBody';
import { CheckoutFooter, CheckoutFooterProps } from '@/components/CheckoutFooter';
import { CheckoutHeader, CheckoutHeaderProps } from '@/components/CheckoutHeader';
import { useScheduled } from '@/hooks/useScheduled';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { ReactNode } from 'react';
import { Checkout } from './Checkout';

export interface StandaloneCheckoutProps extends StandaloneComponentProps<typeof Checkout> {
  header?: CheckoutHeaderProps;
  body?: CheckoutBodyProps;
  footer?: CheckoutFooterProps;
  closed?: { time?: string; copy?: React.ReactNode };
  disabledContent?: ReactNode;
}

export const StandaloneCheckout: StandaloneComponent<StandaloneCheckoutProps> = ({
  header,
  body,
  footer,
  closed,
  disabledContent,
  options,
  ...props
}) => {
  const { enabled } = getOcelotConfig('features.payments');

  const { $form, ...$base } = options ?? {};

  const { time: closeTime, copy: closedCopy } = closed ?? {};

  const isSignUpClosed = useScheduled(closeTime);

  if (!enabled && disabledContent) {
    return disabledContent;
  }

  return (
    <Checkout {...$base} {...props}>
      <CheckoutHeader {...header} />
      <CheckoutBody {...body} />
      <CheckoutFooter {...footer} />

      {isSignUpClosed ? <Checkout.Closed>{closedCopy}</Checkout.Closed> : <Checkout.Form form={$form} />}
    </Checkout>
  );
};
