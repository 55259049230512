import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';
import { isArray } from 'typesafe-utils';

const sessionStorageKey = 'seenArticleIds';

export const saveSeenArticle = (articleId: number) => {
  if (isServer()) return;

  const value = sessionStorage.getItem(sessionStorageKey);

  try {
    const articlesIds: number[] = value ? JSON.parse(value) : [];
    // reverse the array to make sure ids are unique and currently visible article is last element in the array
    const uniqueArticleIds = Array.from(new Set([...articlesIds, articleId].reverse())).reverse();

    sessionStorage.setItem(sessionStorageKey, JSON.stringify(uniqueArticleIds));
  } catch (error) {
    logger.catch(error);
  }
};

export const getSeenArticleIds = (): number[] => {
  if (isServer()) return [];

  const value = sessionStorage.getItem(sessionStorageKey);

  try {
    const data = value ? JSON.parse(value) : [];
    return isArray(data) ? data.map((id: string | number) => String(id)) : [];
  } catch (error) {
    logger.catch(error);
    return [];
  }
};
