import { getRecommendationMicroservice } from '@/utils/getRecommendationMicroservice';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { isConsentDisabled } from '@/utils/isConsentDisabled';
import { logger } from '@/utils/logger';
import { getSeenArticleIds } from '@/utils/seenArticles';
import { brandConfig, defaultRequestOptions, optimizeValuesDefault, qsValuesDefault } from './config';
import { compileOptions, waitForCmpClosed, waitForGoogleAnalytics } from './helpers';
import { DefaultRequestOptions } from './types';

export async function readyToFetch() {
  try {
    await waitForCmpClosed();
  } catch (e) {
    logger.catch('CMP was not closed within timeout.');
    return false;
  }

  const isAllowedToTrack = !isConsentDisabled && window.OnetrustActiveGroups?.indexOf('C0004') >= 0;

  if (isAllowedToTrack) {
    return waitForGoogleAnalytics()
      .then(() => {
        return true;
      })
      .catch((err) => {
        logger.catch('Google Analytics never became available.', err);
        return false;
      });
  }

  logger.info('Not waiting for Google Analytics (not allowed to track).');
  return false;
}

function getGoogleOptimizeSetup() {
  const optimizeSetup = { ...optimizeValuesDefault };

  // Read values from window set by Google Optimize.
  if (Object.prototype.hasOwnProperty.call(window, 'ra_showrecommendations')) {
    optimizeSetup.showRecommendations = !!+window.ra_showrecommendations;
    logger.info('"showRecommendations" value set by Google Optimize, value: ', optimizeSetup.showRecommendations);
  }

  // @todo: legacy name, remove this once test on Elle is finished.
  if (Object.prototype.hasOwnProperty.call(window, 'showRecommendations')) {
    optimizeSetup.showRecommendations = !!+window.showRecommendations;
    logger.info(
      '"showRecommendations" value set by Google Optimize (legacy), value: ',
      optimizeSetup.showRecommendations,
    );
  }

  return optimizeSetup;
}

function getQsSetup() {
  const qsSetup = { ...qsValuesDefault };

  // Read values from query string.
  try {
    const searchParams = new URLSearchParams(window.location.search);

    if (window.location.href.indexOf('ra_gaid=') >= 0) {
      qsSetup.gaId = searchParams.get('ra_gaid');
      logger.info('"gaId" value overridden by query string parameter, value:', qsSetup.gaId);
    }

    if (window.location.href.indexOf('ra_showrecommendations=') >= 0) {
      qsSetup.showRecommendations = !!+(<string>searchParams.get('ra_showrecommendations'));
      logger.info(
        '"showRecommendations" value overridden by query string parameter, value: ',
        qsSetup.showRecommendations,
      );
    }
  } catch (err) {
    logger.catch('URLSearchParams is not available, query string overrides ignored.');
  }
  return qsSetup;
}

export function generalSetup() {
  const optimizeSetup = getGoogleOptimizeSetup();
  const qsSetup = getQsSetup();
  const brand = defaultRequestOptions.brand;
  // Should we show recommendations? (only applies if `defaultHidden: true`).
  const showRecommendations = qsSetup.showRecommendations ?? optimizeSetup.showRecommendations ?? false;

  const options = {
    /* Uncomment these lines for dev or debugging */
    // gaId: '487506200.1630585561',
    // responseTimeout: 5000,
    gaId: '',
    brand,
    showRecommendations,
  };

  // Did we override gaId by providing it in the query string?
  if (qsSetup.gaId !== null) {
    options.gaId = qsSetup.gaId;
  }

  return options;
}
const getContextualAndPersonalizedRecommendations = (requestOptions: DefaultRequestOptions) => {
  const baseUrl = getUrl('/articleRecommendations', getRecommendationMicroservice());
  const body = {
    brand: requestOptions.brand,
    cookieId: requestOptions.gaId,
    articleIds: getSeenArticleIds(),
  };

  return http.post((baseUrl as URL).href, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' },
    timeout: requestOptions.responseTimeout,
  });
};

export async function getRecommendedArticles(articleId: string) {
  const options = generalSetup();
  const requestOptions = compileOptions({ ...options, articleId });

  logger.info('Requesting recommendations.', requestOptions);

  if (brandConfig.defaultHidden && requestOptions.showRecommendations === false) {
    logger.info('Recommendations are hidden by default.');
    return [];
  }

  const response = await getContextualAndPersonalizedRecommendations(requestOptions);

  return response?.data || [];
}
