import { Link as StandaloneLink } from '@/components/Link';
import { ShowsSliderTheme } from '@/components/ShowsSlider/theme';
import { useClassName } from '@/styles/ShowsSlider';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { GenericSlot } from 'base/components/GenericSlot';
import { ShowsSliderCaptionComponent } from './ShowsSlider.Caption';
import { ShowsSliderHeaderComponent } from './ShowsSlider.Header';
import { ShowsSliderSlideComponent } from './ShowsSlider.Slide';
import { ShowsSliderTitleComponent } from './ShowsSlider.Title';

export interface ShowsSliderProps extends ComponentProps<'div'> {}

const ShowsSliderComponent: Component<ShowsSliderProps> = ({ children, ...props }) => {
  const className = useClassName('', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

const Link = GenericSlot({ as: StandaloneLink, theme: ShowsSliderTheme.Link });

export const ShowsSlider = Object.assign(ShowsSliderComponent, {
  Link,
  Caption: ShowsSliderCaptionComponent,
  Header: ShowsSliderHeaderComponent,
  Title: ShowsSliderTitleComponent,
  Slide: ShowsSliderSlideComponent,
});
